import { getRanking } from "connections/ranking";
import React, { useEffect, useState } from "react";
import "./RankingPage.scss";
import { EditionsInfo, RankingInfo } from "connections/models/credentials";
import { getEditionsInfo } from "connections/edition";
import Ranking from "./components/Ranking";
import { ClipLoader } from "react-spinners";

const RankingPage: React.FC = () => {
  const [rankingInfo, setRankingInfo] = useState<RankingInfo | null>(null);
  const [editionsInfo, setEditionsInfo] = useState<EditionsInfo[]>([]);
  useEffect(() => {
    (async (): Promise<void> => {
      const responseEditions = await getEditionsInfo();
      if (responseEditions.status === "success") {
        setEditionsInfo(responseEditions.data);
      }
      const responseRanking = await getRanking();
      if (responseRanking.status === "success") {
        setRankingInfo(responseRanking.data);
      }
    })();
  }, []);
  return (
    <div className="ranking-container">
      <header>
        <h1 className="mr-2">Ranking edycji {editionsInfo[0] ? editionsInfo[0].name : ""}</h1>
        <div className="has-text-centered">
          <a href="archiwalny-ranking">Archiwalne edycje</a>
        </div>
      </header>

      <main>
        <div className="has-text-centered mb-2">
          {rankingInfo ? (
            rankingInfo?.user_rank ? (
              `Twoje miejsce w tej edycji: ${rankingInfo.user_rank}`
            ) : (
              "Nie brałeś udziału w tej edycji"
            )
          ) : (
            <ClipLoader size={100} />
          )}
        </div>
        <Ranking rankingInfo={rankingInfo} />
      </main>
    </div>
  );
};

export default RankingPage;
