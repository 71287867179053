import React from "react";
import { useParams } from "react-router-dom";
import "./ErrorPage.scss";

interface ParamTypes {
  errorNumber: string;
}

const ErrorPage: React.FC = () => {
  const { errorNumber } = useParams<ParamTypes>();
  return (
    <main className="error-container__background">
      <div className="error-container">
        <h1 className="error-container__title">Błąd {errorNumber}</h1>
        <div>
          <p>Wystąpił błąd serwera, przepraszamy za problemy</p>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
