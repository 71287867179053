import React from "react";
import "./DetectivePage.scss";

const DetectivePage: React.FC = () => {
  return (
    <div className="detective-container__background">
      <main className="detective-container__main">
        <section>
          <div>
            <div className="detective-container__header">
              <h1>Kim są językowi detektywi?</h1>
            </div>
            <p>
              Skoncentrowany jak Sherlock Holmes, przebiegły jak Joanna Chyłka i bezkompromisowy jak Harry Hole. Lubi
              analizować, snuć domysły i zastanawiać się, co by było, gdyby… Czasem zatraca się w lekturze, z pasją
              obserwując jak słowa niczym klocki Lego składają się w zgrabne zdania. To więc naturalne, że w księgarni
              czuje się jak Anastasia przy Greyu, a na nowości od ulubionych autorów czeka jak Wokulski na Łęcką. Oto
              portret idealnych językowych detektywów. Chcesz być jak oni? Jeśli tak, to czytaj dalej, bo dzięki temu
              dowiesz się, jak zasilić ich szeregi, pomóc rozwiązać największą zagadkę językową na Warmii, a przy okazji
              wziąć udział w konkursie i co tydzień powalczyć o{" "}
              <b>czytnik Amazon Kindle 10 i bony podarunkowe do empik.com</b>!
            </p>
          </div>
        </section>
        <section>
          <div>
            <div className="detective-container__header">
              <h1>Co robią językowi detektywi?</h1>
            </div>
            <p>
              Zadanie agentów będzie proste jak włosy Wiedźmina, a zarazem intrygujące jak uśmiech Ryana Gosslinga w
              „Blue Valentine”. W ośrodku naukowo-badawczym w centrum Warmii w niewyjaśnionych okolicznościach giną
              słowa. Co tydzień na koncie tajnych agentów pojawi się sześć tekstów-zagadek. W maszynopisach brakować
              będzie kilkudziesięciu wybranych wyrazów. Rolą detektywów będzie uważne przeczytanie fragmentu i
              uzupełnienie luk słowami, które mogły się znajdować w oryginalnym artykule (tj. takimi, które w ich ocenie
              najlepiej pasują do kontekstu). Na wykonanie tego zadania każdy z nich będzie miał po 13 minut.
            </p>
          </div>
        </section>
        <section>
          <div>
            <div className="detective-container__header">
              <h1>Za co detektywi językowi zdobywają punkty?</h1>
            </div>
            <p>
              Istnieją dwie drogi, by być kryminalnym punkt-masterem: rozwiązywanie zagadek i werbowanie kolejnych
              agentów! Punkty za rozwikłane przez detektywów śledztwa początkowo trafią do poczekalni. Nasza ekipa rzuci
              na nie okiem i sprawdzi, czy teksty-zagadki zostały rozszyfrowane zgodnie z regulaminem. Od III edycji
              postanowiliśmy też<b>dodatkowo nagradzać sumiennych i superskutecznych detektywów</b>! Każdy z agentów
              otrzyma ekstrapunkty za średnią poprawność rozwikłanych zagadek. Obliczając, ile powinna wynosić ta
              premia, uwzględnimy średnią liczbę odpowiedzi zaakceptowanych przez naszych wnikliwych weryfikatorów. Pod
              lupę weźmiemy każdą z zagadek detektywów – również te, za które nie otrzymali oni stardardowych punktów.
              Każdy z kryminalnych adeptów po rozwikłaniu trzech śledztw otrzyma licencję detektywa, a wraz z nią
              supertajny kod! To właśnie dzięki niemu będzie mógł zapraszać do zabawy swoich znajomych, zdobywając
              jeszcze więcej punktów!
            </p>
          </div>
        </section>
        <section>
          <div>
            <div className="detective-container__header">
              <h1>Co wygrywają detektywi językowi?</h1>
            </div>
            <p>
              Wiemy, że wcielanie się w rolę językowego agenta to niełatwe zadanie – minuty koncentracji przed ekranem
              komputera potrafią dać się we znaki nawet najbardziej doświadczonym żonglerom dedukcji. Zespół naukowców i
              programistów GoodWrite postanowił więc nagrodzić najbardziej przebiegłych detektywów. Co tydzień czekać na
              nich będą supernagrody! Do zgarnięcia{" "}
              <b>czytniki Amazon Kindle 10 i bony podarunkowe do empik.com za 100 zł i 50 zł</b>!
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DetectivePage;
