import React, { useEffect } from "react";
import "./ConfirmEmailPage.scss";

import { UserStatus } from "utils/enums";
import { history } from "utils/history";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";

const ConfirmEmailPage: React.FC = () => {
  const questionnaireStatus = useSelector((state: RootState) => state.me.info?.status);
  useEffect(() => {
    if (questionnaireStatus === UserStatus.HAS_ENTRY) {
      history.push("/kwestionariusz");
    }
  }, [questionnaireStatus]);
  return (
    <main className="confirm-email-container__background">
      <div className="confirm-email-container">
        <h1 className="confirm-email-container__title">Witamy w gronie językowych detektywów!</h1>
        <div>
          <p>Możesz teraz zalogować się na swoje konto, prowadzić językowe śledztwa i wygrywać supernagrody!</p>
        </div>
        <p>
          Przejdź do <Link to="/">strony logowania</Link>.
        </p>
        <p className="mt-4">PS. Skoro już jesteś jednym z nas, to możemy Ci zdradzić, że jesteśmy też na Facebooku </p>
        <p>
          Zajrzyj, przejrzyj i jeśli Ci się spodoba, polub nasz fanpage:{" "}
          <a href="https://www.facebook.com/goodwrite.pl" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/goodwrite.pl
          </a>
        </p>
      </div>
    </main>
  );
};

export default ConfirmEmailPage;
