import { AppAction } from "redux/actions";
import { Questionnaire } from "./state";

const moduleName = "questionnaire";
export const GET_QUESTIONNAIRE = `${moduleName}/GET_QUESTIONNAIRE`;
export const SET_ACCEPTED_QUESTIONNAIRE = `${moduleName}/SET_QUESTIONNAIRE`;

export const getQuestionnaireAction = (payload: Questionnaire | null): AppAction => ({
  type: GET_QUESTIONNAIRE,
  payload,
});

export const setAcceptedAction = (payload: Questionnaire | null): AppAction => ({
  type: SET_ACCEPTED_QUESTIONNAIRE,
  payload,
});
