export const polishPlural = (
  singularNominativ: string,
  pluralNominativ: string,
  pluralGenitive: string,
  value: number | undefined
): string => {
  if ((value as number) === 1) {
    return singularNominativ;
  } else if (
    (value as number) % 10 >= 2 &&
    (value as number) % 10 <= 4 &&
    ((value as number) % 100 < 10 || (value as number) % 100 >= 20)
  ) {
    return pluralNominativ;
  } else {
    return pluralGenitive;
  }
};
