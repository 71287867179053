import React from "react";

import { RootState } from "redux/appState";
import { useSelector } from "react-redux";

import ScoreSection from "./ScoreSection";
import { EditionResult } from "redux/me/state";
import { EditionInfo } from "connections/models/credentials";

interface ScoreSections {
  editions: EditionResult[];
  StartQuestionnaireBar: () => JSX.Element;
  editionInfo: EditionInfo;
}

const ScoreSections: React.FC<ScoreSections> = ({ editions, StartQuestionnaireBar, editionInfo }) => {
  const userHasCurrentStats = useSelector((state: RootState) => state.me.info?.has_current_stats);
  const [allTriedCount, acceptedTriedCount] = useSelector((state: RootState) => [
    state.me.info?.current_tries,
    state.me.info?.current_tries_with_prerequisites_met,
    state.me.info?.friends_count,
  ]);
  const { current_edition, incoming_edition } = editionInfo;
  const NoEditionInfo: JSX.Element = (
    <section>
      <article className="dashboard-container__article">
        <div className="dashboard-container__article--title">
          <h1>Aktualnie wysłaliśmy naszych detektywów zasłużony odpoczynek.</h1>
          <h2>Jeśli uruchomimy kolejną edycję językowych śledztw, to wyślemy informację na Twój adres e-mail.</h2>
        </div>
      </article>
    </section>
  );
  const IncomingInfoSection: JSX.Element = (
    <section>
      <article className="dashboard-container__article">
        <div className="dashboard-container__article--title">
          <h1>Aktualnie wysłaliśmy naszych detektywów zasłużony odpoczynek.</h1>
          <div className="dashboard-container__article--bold">
            {`${incoming_edition?.name} zacznie się ${incoming_edition?.start_date} i będzie trwała do ${incoming_edition?.end_date}`}
          </div>
        </div>
      </article>
    </section>
  );
  const StatsSections: JSX.Element = (
    <>
      {editions.map((edition) => {
        const { edition_info, edition_results } = edition;
        return (
          <section key={edition.id}>
            <ScoreSection
              currentEdition={edition_info.is_current}
              key={edition.id}
              allTriedCount={edition_results.tries}
              acceptedTriedCount={edition_results.tries_with_prerequisites_met}
              totalPoints={edition_results.total_points}
              friendPoints={edition_results.friend_points}
              entryPoints={edition_results.entry_points}
              rank={edition_results.rank}
              editionName={edition_info.name}
              startDate={edition_info.start_date}
              endDate={edition_info.end_date}
            />
            {edition_info.is_current && StartQuestionnaireBar()}
          </section>
        );
      })}
    </>
  );
  if (!userHasCurrentStats) {
    return (
      <main>
        <div className="dashboard-container">
          {current_edition === null && incoming_edition === null && NoEditionInfo}
          {current_edition === null && incoming_edition && IncomingInfoSection}
          {current_edition && (
            <section>
              <article className="dashboard-container__article">
                <div className="dashboard-container__article--title">
                  <h1>Rozwiąż zagadkę, aby otrzymać dostęp do swoich kryminalnych statystyk</h1>
                  <div className="dashboard-container__article--bold">
                    {`${current_edition.name} trwa od ${current_edition.start_date} do ${current_edition.end_date}.`}
                  </div>
                </div>
                <div className="dashboard-container__article__grid dashboard-container__article__grid--one-row">
                  <div>
                    <div className="dashboard-container__article--number">{acceptedTriedCount} / 20</div>
                    <div className="dashboard-container__article--bold">Rozwiązanych zagadek</div>
                    <div>Ilość wszystkich prób: {allTriedCount}</div>
                  </div>
                </div>
              </article>
            </section>
          )}
          {current_edition && StartQuestionnaireBar()}
          {/*{StatsSections}*/}
        </div>
      </main>
    );
  } else {
    return (
      <main>
        <div className="dashboard-container">
          {current_edition === null && incoming_edition === null && NoEditionInfo}
          {current_edition === null && incoming_edition && IncomingInfoSection}
          {StatsSections}
        </div>
      </main>
    );
  }
};

export default ScoreSections;
