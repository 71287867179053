import { RankInfo } from "connections/models/credentials";
import React from "react";

interface RankingProps {
  rankingInfo: RankInfo[] | null;
}

const Ranking: React.FC<RankingProps> = ({ rankingInfo }) => {
  return (
    <div className="ranking-container__ranking">
      <div className="ranking-container__place">
        <div className="rank ranking-container__place--bold">Miejsce</div>
        <div className="email ranking-container__place--bold">Adres e-mail</div>
        <div className="points ranking-container__place--bold">Punkty</div>
      </div>
      {rankingInfo &&
        rankingInfo.map((user) => {
          return (
            <div key={user.rank} className="ranking-container__place">
              <div className="rank ranking-container__place--big">{user.rank}</div>
              <div className="email">{user.email}</div>
              <div className="points">{user.total_points}</div>
            </div>
          );
        })}
    </div>
  );
};

export default Ranking;
