import React, { useState } from "react";
import "./NavBar.scss";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "redux/appState";
import { AppStatus } from "utils/enums";

import ClozeLogo from "../../img/ClozeLogo.png";

const GuestNavBar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const appStatus = useSelector((state: RootState) => state.appStatus.status);

  const handleClick = (): void => {
    setIsExpanded((previousValue) => !previousValue);
  };

  if (appStatus === AppStatus.QUESTIONNAIRE_IN_PROGRESS) {
    return <div></div>;
  } else {
    return (
      <div className="container-navbar container-navbar__guest">
        <nav className="navbar navbar__guest is-info" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <div className="navbar-item navbar-item__logo">
              <Link to="/">
                <img src={ClozeLogo} alt="Językowi detektywi" />
              </Link>
            </div>
            <div
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={handleClick}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${isExpanded ? "is-active" : ""}`}>
            <div className="navbar-end">
              <Link to="/o-detektywach" className="navbar-item" onClick={handleClick}>
                O konkursie
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default GuestNavBar;
