import { SET_LOGGED_IN, CHANGE_PASSWORD } from "./actions";
import { AppAction } from "redux/actions";
import AuthState from "./state";

const initialState: AuthState = {
  isLoggedIn: null,
};

export function authReducer(state = initialState, action: AppAction): AuthState {
  switch (action.type) {
    case SET_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
