import client from "./client";
import Response from "./envelope";
import { parseToSuccessResponse, parseToErrorResponse, parseToSuccessResponseResults } from "./models/responseParsers";
import { EditionInfo, EditionsInfo } from "./models/credentials";

export async function getEditionInfo(): Promise<Response<EditionInfo>> {
  try {
    const response = await client.get("/edition/info/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getEditionsInfo(): Promise<Response<EditionsInfo[]>> {
  try {
    const response = await client.get("/edition/");
    return parseToSuccessResponseResults(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
