import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  return (
    <footer className={`footer-container ${className}`}>
      <div>
        Copyright © 2020
        <a
          className="footer-container__link footer-container__link--white"
          target="_blank"
          rel="noopener noreferrer"
          href="https://ermlab.com/"
        >
          Ermlab Software
        </a>
        . Wszelkie prawa zastrzeżone.
      </div>
      <div>
        <Link to="/regulamin" className="footer-container__link footer-container__link--white">
          Regulamin konkursu
        </Link>
        <Link to="/polityka-prywatnosci" className="footer-container__link footer-container__link--white">
          Polityka prywatności
        </Link>
        <Link to="/o-projekcie" className="footer-container__link footer-container__link--white">
          O projekcie
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
