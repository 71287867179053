import client from "./client";
import Response from "./envelope";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";
import { RankingInfo } from "./models/credentials";

export async function getRanking(id = 0): Promise<Response<RankingInfo>> {
  try {
    const response = await client.get(id === 0 ? "/ranking/" : `/ranking/?edition=${id}`);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
