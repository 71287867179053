import React from "react";
import "components/app/components/questionnaire/CheckQuestionnairePage.scss";
import "components/app/components/questionnaire/QuestionnairePage.scss";

import CheckQuestionnaire from "./components/CheckQuestionnaire";

const CheckQuestionnairePage: React.FC = () => {
  return (
    <div className="questionnaire-container check-questionnaire-container pt-6">
      <CheckQuestionnaire />
    </div>
  );
};

export default CheckQuestionnairePage;
