/* eslint-disable no-irregular-whitespace */
import React from "react";
import "./RulesPage.scss";

const RulesPage: React.FC = () => {
  return (
    <div className="rules-container__background">
      <main className="rules-container">
        <div className="rules-container__title">
          <h1>REGULAMIN KONKURSU „JĘZYKOWI DETEKTYWI”</h1>
        </div>
        <div className="content">
          <ol className="rules-container__ol" type="I">
            <li className="rules-container__li">
              <a href="#1">Postanowienia ogólne</a>
            </li>
            <li className="rules-container__li">
              <a href="#2">Warunki uczestnictwa w Konkursie</a>
            </li>
            <li className="rules-container__li">
              <a href="#3">Zasady Konkursu</a>
            </li>
            <li className="rules-container__li">
              <a href="#4">Nagrody konkursowe</a>
            </li>
            <li className="rules-container__li">
              <a href="#5">Postępowanie reklamacyjne</a>
            </li>
            <li className="rules-container__li">
              <a href="#6">Ochrona danych osobowych</a>
            </li>
            <li className="rules-container__li">
              <a href="#7">Postanowienia końcowe</a>
            </li>
          </ol>
        </div>
        <div className="rules-container__jump-to" id="1"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">I Postanowienia ogólne</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Organizatorem Konkursu jest Ermlab Sp. z o.o., z siedzibą w Olsztynie, przy ul. Trylińskiego 16,
                właściciel marki GoodWrite.pl, zwana w dalszej części niniejszego Regulaminu „Organizatorem”.
              </li>
              <li className="rules-container__li">
                Konkurs przeprowadzany jest na podstawie zasad i postanowień określonych w niniejszym Regulaminie.
              </li>
              <li className="rules-container__li">
                Konkurs będzie przeprowadzony w maksymalnie pięciu niezależnych etapach, za pośrednictwem strony
                https://detektywi.goodwrite.pl, zwanej dalej „systemem”, od 26.10.2020 r.
              </li>
              <li className="rules-container__li">
                Terminy trwania poszczególnych etapów podawane będą przez Organizatora odpowiednio wcześniej na stronie
                i internetowej Konkursu. Poszczególne etapy odbędą się:
                <ol className="rules-container__ol" type="1">
                  <li className="rules-container__li">
                    Etap pierwszy: w terminie 26.10.2020 (od godziny 0:01) do 1.11.2020 (do godziny 23:59).
                  </li>
                  <li className="rules-container__li">
                    Etap drugi: w terminie 9.11.2020 (od godziny 0:01) do 15.11.2020 (do godziny 23:59).
                  </li>
                  <li className="rules-container__li">
                    Etap trzeci: w terminie 30.11.2020 (od godziny 00:01) do 6.12.2020 (do godziny 23:59).
                  </li>
                  <li className="rules-container__li">Terminy etapu czwartego zostanie podany 11.12.2020 r.</li>
                </ol>
              </li>
              <li className="rules-container__li">
                Organizator zastrzega sobie prawo do zmiany postanowień niniejszego Regulaminu lub harmonogramu
                poszczególnych etapów Konkursu bez podawania przyczyny.
              </li>
              <li className="rules-container__li">
                Konkurs nie jest loterią promocyjną w rozumieniu Art. 2 ust. 1 pkt. 9 Ustawy o grach i zakładach
                wzajemnych z dnia 29 lipca 1992 r.. (Dz. U. 04.4.27 z późn. zm.) i nie podlega regułom zawartym w w/w
                Ustawie oraz rozporządzeniach wykonawczych do tej Ustawy.
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="2"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">II Warunki uczestnictwa w Konkursie</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Uczestnikami Konkursu (zwanymi w dalszej części niniejszego Regulaminu również „Użytkownikami systemu”)
                mogą być osoby pełnoletnie oraz niepełnoletnie powyżej 16. roku życia za zgodą opiekunów prawnych,
                zamieszkałe na terytorium Rzeczypospolitej Polskiej, które spełnią wymagania określone w p. II. 2.
                niniejszego Regulaminu i zasady opisane w p. III Regulaminu.
              </li>
              <li className="rules-container__li">
                Uczestnikami Konkursu nie mogą być pracownicy i współpracownicy Organizatora ani ich małżonkowie,
                wstępni oraz zstępni – osoby takie zostaną wykluczone z Konkursu.
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="3"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">III Zasady Konkursu</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Zadanie konkursowe
                <ol className="rules-container__ol" type="a">
                  <li className="rules-container__li">
                    Użytkownik systemu po zalogowaniu otrzyma do wypełnienia wybrane losowo teksty o różnym poziomie
                    trudności. Każdy tekst ma długość około 300 słów; w tekście część słów została usunięta i zastąpiona
                    luką. Zadaniem Użytkownika jest poprawne uzupełnienie jak największej liczby luk wybranymi przez
                    siebie słowami (które w ocenie Użytkownika najbardziej pasują do kontekstu), tak, by tekst tworzył
                    poprawną logicznie całość (zalecane jest zwrócenie uwagi na wielkość liter, ortografię i obecność
                    polskich znaków diakrytycznych). Limit czasowy na wypełnienie każdego z tekstów wynosi
                    13 minut. Warunkiem koniecznym, aby wypełniony tekst został zaliczony przez system, a punkty
                    zaksięgowane na koncie Użytkownika, jest naciśnięcie przycisku „wyślij” po uzupełnieniu luk.
                  </li>
                  <li className="rules-container__li">
                    Użytkownik systemu w każdej turze Konkursu może wypełnić maksymalnie 20 tekstów.
                  </li>
                  <li className="rules-container__li">
                    Sposób naliczania punktów za wypełnione teksty reguluje p. III. 3. niniejszego Regulaminu.
                  </li>
                </ol>
              </li>
              <li className="rules-container__li">
                Kod polecający i system rekomendacji
                <ol className="rules-container__ol" type="a">
                  <li className="rules-container__li">
                    Każdy z aktywnych Użytkowników systemu otrzymuje dostęp do indywidualnego kodu polecającego.
                  </li>
                  <li className="rules-container__li">
                    Przez „aktywnego Użytkownika” rozumie się użytkownika, który poprawnie przeszedł procedurę logowania
                    i poprawnie uzupełnił trzy teksty w systemie.
                  </li>
                  <li className="rules-container__li">
                    Przez „kod polecający” rozumie się unikalny identyfikator Użytkownika nadany Użytkownikowi i zaszyty
                    w aktywnym linku, służący do celów rozpoznania kodu ID Użytkownika na potrzeby niniejszego Konkursu.
                  </li>
                  <li className="rules-container__li">
                    Użytkownik może przekazać swój kod polecający osobie trzeciej, spełniającej warunki określone w p.
                    II niniejszego Regulaminu.
                  </li>
                  <li className="rules-container__li">
                    Kod polecający jest wielokrotnego użytku. Jeden Użytkownik może przekazać kod polecający więcej niż
                    jednej osobie.
                  </li>
                  <li className="rules-container__li">
                    Punkty za polecenia zostaną zaksięgowane na koncie użytkownika tylko wtedy, gdy osoba przez niego
                    polecona uzyska status aktywnego użytkownika.
                  </li>
                  <li className="rules-container__li">
                    Sposób naliczania punktów za polecenia reguluje p. III. 3. niniejszego Regulaminu.
                  </li>
                </ol>
              </li>
              <li className="rules-container__li">
                Kapituła konkursu i system punktowania
                <ol className="rules-container__ol" type="a">
                  <li className="rules-container__li">
                    Kapituła Konkursu składające się z przedstawicieli Organizatorów wybierze w każdy piątek po
                    zakończeniu danego tygodnia trwania konkursu (np. dla tury trwającej w dniach 26.10–1.11.2020 r.:
                    piątek 6.11.2020 r.) 31 laureatów nagród tygodniowych.
                  </li>
                  <li className="rules-container__li">
                    O przyznaniu nagród decyduje liczba zgromadzonych w danym tygodniu punktów. W przypadku tej samej
                    liczby punktów decyduje pierwszeństwo czasowe zaksięgowania punktów w systemie.
                  </li>
                  <li className="rules-container__li">
                    Punktacja jest uzależniona od liczby wypełnionych tekstów, dokładności wypełniania poszczególnych
                    tekstów oraz liczby poleceń i obliczana jest według następujących zasad:
                    <ul className="rules-container__ul">
                      <li className="rules-container__li">
                        Za wypełnienie każdego z tekstów Użytkownik otrzyma 5 pkt. Maksymalna możliwa do zdobycia liczba
                        punktów za wypełnienie tekstów wynosi 100 pkt.
                      </li>
                      <li className="rules-container__li">
                        Użytkownik otrzyma również punkty dodatkowe za średnią poprawność wypełnionych tekstów. Przez
                        „średnią poprawność” rozumie się średnią liczbę odpowiedzi Użytkownika zgodnych z tekstem
                        oryginalnym (tj. takich, które podczas weryfikacji zostały zaakceptowane przez Organizatora).
                        Przy szacowaniu liczby punktów dodatkowych Organizator weźmie pod uwagę wynik z wszystkich
                        tekstów rozwiązanych przez Użytkownika (tj. również tych, za które Użytkownik nie otrzymał
                        punktów).
                      </li>
                      <li className="rules-container__li">
                        Za każde polecenie systemu kolejnej osobie Użytkownik otrzyma 1 pkt.
                      </li>
                    </ul>
                  </li>
                  <li className="rules-container__li">
                    Punkty za wypełnione teksty oraz za poprawność wypełnionych tekstów zostaną zaksięgowane na koncie
                    Użytkownika po weryfikacji tekstu przez Organizatora.
                  </li>
                  <li className="rules-container__li">
                    Organizator zastrzega sobie prawo do nieprzyznania punktów za wypełniony tekst i/lub polecenia
                    użytkowników w przypadku, gdy sposób wypełnienia tekstu przez Użytkownika w rażący sposób narusza
                    zasady wypełniania tekstów opisane w p. III. 1. a. niniejszego Regulaminu.
                  </li>
                  <li className="rules-container__li">
                    Organizator zastrzega sobie prawo do blokowania kont Użytkowników, którzy trzykrotnie w rażący
                    sposób naruszyli zasady wypełniania tekstów opisane w p. III. 1. a. niniejszego Regulaminu.
                  </li>
                  <li className="rules-container__li">Decyzje Kapituły są ostateczne.</li>
                </ol>
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="4"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">IV Nagrody konkursowe</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Nagrody w Konkursie mają postać nagród tygodniowych i przyznawane są Użytkownikom najpóźniej do piątku
                po zakończeniu każdego tygodnia trwania konkursu (por. III. 3. a. niniejszego Regulaminu).
              </li>
              <li className="rules-container__li">
                Nagrodami w konkursie są: czytnik Kindle Amazon 10 (1 sztuka) oraz eKarty prezentowe o wartości 100 zł
                (10 sztuk) oraz 50 zł (20 sztuk) do realizacji w sklepie empik.com, przy czym po każdym tygodniu trwania
                Konkursu przyznanych zostanie łącznie 31 nagród. Łączna liczba nagród we wszystkich turach Konkursu to 5
                czytników Amazon Kindle 10, 50 eKart o wartości 100 zł oraz 100 eKart o wartości 50zł.
              </li>
              <li className="rules-container__li">
                Nagrody nie podlegają wymianie na inną nagrodę lub ekwiwalent pieniężny.
              </li>
              <li className="rules-container__li">
                Fundatorem nagród jest Ermlab Sp. z o.o., z siedzibą w Olsztynie, przy ul. Trylińskiego 16.
              </li>
              <li className="rules-container__li">
                Laureaci zostaną powiadomieni o nagrodach mailowo, na adres podany w panelu użytkownika w ciągu 24
                godzin od podsumowania Konkursu, a wynik każdej z tur Konkursu zostanie opublikowany na stronie
                Konkursu.
              </li>
              <li className="rules-container__li">
                Organizator nie ponosi odpowiedzialności za popełnione przez Użytkownika usterki w zapisie adresu i
                zastrzega sobie prawo do odmowy przyznania nagrody w przypadku, gdy Uczestnik w procesie rejestracji
                konta posłużył się nieaktywnym adresem e-mail.
              </li>
              <li className="rules-container__li">
                Organizator zastrzega sobie prawo do przyznawania dodatkowych nagród i wyróżnień.
              </li>
              <li className="rules-container__li">
                W przypadku, gdy przed przyznaniem nagrody lub po jej przyznaniu okaże się, iż Uczestnik w jakikolwiek
                sposób naruszył postanowienia niniejszego Regulaminu, Organizator zastrzega sobie prawo do wstrzymania
                przekazania nagrody do czasu wyjaśnienia wątpliwości albo do odmówienia przekazania nagrody danemu
                Uczestnikowi, albo – w przypadku nagród już przekazanych – do domagania się ich zwrotu. W takim
                przypadku zastosowanie znajdą odpowiednie przepisy prawa polskiego.
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="5"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">V Postępowanie reklamacyjne</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Wszelkie reklamacje dotyczące spraw związanych z Konkursem należy przesyłać na adres siedziby
                Organizatora na piśmie wraz z oznaczeniem nazwy Konkursu, dokładnym opisem reklamacji i wskazaniem
                rodzaju naruszenia w terminie 21 dni od daty zakończenia Konkursu. Reklamacje złożone po upływie 21 dni
                od daty zakończenia Konkursu zostaną pozostawione bez rozpoznania.
              </li>
              <li className="rules-container__li">
                Reklamacje mogą być również przesyłane na następujący adres poczty elektronicznej Organizatora:
                konkurs@goodwrite.pl.
              </li>
              <li className="rules-container__li">
                Organizator rozpatrzy reklamację w terminie 30 dni od daty jej otrzymania i udzieli odpowiedzi na
                reklamację w formie pisemnej lub elektronicznej, w zależności od sposobu złożenia reklamacji.
              </li>
              <li className="rules-container__li">Decyzja Organizatora w przedmiocie reklamacji jest ostateczna.</li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="6"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">VI Ochrona danych osobowych</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Każdy Uczestnik poprzez przystąpienie do Konkursu wyraża zgodę na przetwarzanie przez Organizatora
                danych osobowych zgodnie z przepisami ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych
                (tekst jednolity: Dz. U. z 2002 r., Nr 101 poz. 926) oraz zgodnie z ogólnym rozporządzeniem o ochronie
                danych osobowych Rozporządzeniem Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. (UE)
                2016/679 dalej „Rozporządzeniem”).
              </li>
              <li className="rules-container__li">
                Administratorem danych osobowych Uczestników jest Ermlab Sp. z o.o., z siedzibą w Olsztynie, przy ul.
                Trylińskiego 16. Administrator wyznaczył inspektora ochrony danych, z którym można skontaktować się
                poprzez e-mail: kwitkowska@ermlab.com w każdej sprawie dotyczącej przetwarzania danych osobowych
                Uczestników Konkursu.
              </li>
              <li className="rules-container__li">
                Dane osobowe Uczestników Konkursu będą przetwarzane w celu realizacji Konkursu (w tym wyłonienia osób
                nagrodzonych i wręczenia nagród), w tym w celu zapewnienia możliwości ustalenia lub dochodzenia
                ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Administratora – podstawą prawną
                przetwarzania danych jest zgoda osoby, której dane dotyczą (art. 6 ust. 1 lit. a) Rozporządzenia) oraz
                prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit f) Rozporządzenia), a także na potrzeby
                prac badawczo-rozwojowych prowadzonych w ramach projektu nr POIR.01.01.01- 00-1128/18-01 Szybka ścieżka
                NCBR, pn. Technologia kontekstowego rozumienia języka pisanego na potrzeby poprawy błędów oraz
                automatycznej oceny zrozumiałości tekstu.
              </li>
              <li className="rules-container__li">
                Dane osobowe Uczestników Konkursu mogą być przekazywane wyłącznie podmiotom świadczącym usługi na rzecz
                Administratora, wyłącznie w związku z realizacją Konkursu, m.in. podmiotom świadczącym usługi księgowe,
                dostawcom systemów informatycznych i usług IT, podmiotom świadczącym usługi prawne, podmiotom
                świadczącym usługi archiwizacji dokumentów. Dane osobowe Uczestników Konkursu mogą być także
                przekazywane uprawnionym organom w zakresie wymaganym przez obowiązujące przepisy prawa.
              </li>
              <li className="rules-container__li">
                Dane osobowe Uczestników Konkursu będą przetwarzane przez okres istnienia prawnie uzasadnionego interesu
                Administratora, chyba że Uczestnik wyrazi sprzeciw wobec przetwarzania jego danych osobowych lub cofnie
                zgodę na przetwarzanie jego danych osobowych. Okres przetwarzania danych osobowych może zostać
                każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie
                niezbędne dla dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez
                Administratora. Po tym okresie dane będą przetwarzane jedynie w zakresie i przez czas wymagany
                przepisami prawa.
              </li>
              <li className="rules-container__li">
                Uczestnikowi przysługuje prawo: dostępu do treści danych oraz żądania ich sprostowania, usunięcia,
                ograniczenia przetwarzania, prawo do przenoszenia danych osobowych, wniesienia sprzeciwu względem
                przetwarzania danych oraz prawo do wycofania zgody w dowolnym momencie. Wycofanie zgody nie ma wpływu na
                zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
              </li>
              <li className="rules-container__li">
                Uczestnikowi przysługuje także prawo wniesienia sprzeciwu względem przetwarzania danych osobowych z
                przyczyn związanych z jego szczególną sytuacją.
              </li>
              <li className="rules-container__li">
                Uczestnikowi przysługuje także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną
                danych osobowych, gdy uzna, że przetwarzanie danych osobowych narusza przepisy Rozporządzenia.
              </li>
              <li className="rules-container__li">
                Podanie danych osobowych i wyrażenie zgody przez Uczestnika na ich przetwarzanie jest dobrowolne, jednak
                ich niepodanie lub brak zgody uniemożliwia realizację wydania nagrody.
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="7"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">VII Postanowienia końcowe</div>
          <div className="content">
            <ol className="rules-container__ol" type="1">
              <li className="rules-container__li">
                Poprzez przystąpienie do Konkursu Uczestnik oświadcza, że zapoznał się z niniejszym Regulaminem i
                akceptuje jego postanowienia.
              </li>
              <li className="rules-container__li">
                Wszelkie ewentualne zmiany niniejszego Regulaminu zostaną opublikowane na stronie
                https://detektywi.goodwrite.pl, przy czym zmiany nie mogą naruszać, ograniczać ani pozbawiać Uczestników
                Konkursu praw przez nich już nabytych.
              </li>
              <li className="rules-container__li">
                W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają odpowiednie przepisy prawa
                polskiego.
              </li>
              <li className="rules-container__li">
                Niniejszy Regulamin wchodzi w życie w dniu 26.10.2020 r. i obowiązuje do momentu zakończenia Konkursu.
              </li>
            </ol>
          </div>
        </section>
      </main>
    </div>
  );
};

export default RulesPage;
