import { RankInfo } from "connections/models/credentials";

const rankingInfo: RankInfo[][] = [
  [],
  [],
  [],
  [],
  [],
  [],
  [
    {
      email: "fa****exe@gmail.com",
      rank: 1,
      total_points: 331,
    },
    {
      email: "ch****r07@op.pl",
      rank: 2,
      total_points: 261,
    },
    {
      email: "jul******duch@gmail.com",
      rank: 3,
      total_points: 171,
    },
    {
      email: "dzie********lia83@gmail.com",
      rank: 4,
      total_points: 136,
    },
    {
      email: "ke****23@interia.pl",
      rank: 5,
      total_points: 121,
    },
    {
      email: "g**m1@wp.pl",
      rank: 6,
      total_points: 111,
    },
    {
      email: "nat******rol7@gmail.com",
      rank: 7,
      total_points: 111,
    },
    {
      email: "mi*****lu2@o2.pl",
      rank: 8,
      total_points: 111,
    },
    {
      email: "il*****007@o2.pl",
      rank: 9,
      total_points: 110,
    },
    {
      email: "ma****24@wp.pl",
      rank: 10,
      total_points: 106,
    },
    {
      email: "just*********takl1c@gmail.com",
      rank: 11,
      total_points: 96,
    },
    {
      email: "karo********muga@gmail.com",
      rank: 12,
      total_points: 96,
    },
    {
      email: "mar*******ynska@gmail.com",
      rank: 13,
      total_points: 91,
    },
    {
      email: "zs****sz@interia.pl",
      rank: 14,
      total_points: 86,
    },
    {
      email: "li****us1@gmail.com",
      rank: 15,
      total_points: 81,
    },
    {
      email: "nik*******ska07@gmail.com",
      rank: 16,
      total_points: 66,
    },
    {
      email: "ma*****142@interia.eu",
      rank: 17,
      total_points: 61,
    },
    {
      email: "e**i@o2.pl",
      rank: 18,
      total_points: 61,
    },
    {
      email: "semb*********dorota@gmail.com",
      rank: 19,
      total_points: 56,
    },
    {
      email: "aga*******owicz@op.pl",
      rank: 20,
      total_points: 56,
    },
    {
      email: "i**2@interia.eu",
      rank: 21,
      total_points: 56,
    },
    {
      email: "o***266@o2.pl",
      rank: 22,
      total_points: 56,
    },
    {
      email: "ma*****095@gmail.com",
      rank: 23,
      total_points: 51,
    },
    {
      email: "ser*******zina@gmail.com",
      rank: 24,
      total_points: 51,
    },
    {
      email: "aks*******enata@wp.pl",
      rank: 25,
      total_points: 51,
    },
    {
      email: "sylw********post@gmail.com",
      rank: 26,
      total_points: 51,
    },
    {
      email: "w***ek@o2.pl",
      rank: 27,
      total_points: 46,
    },
    {
      email: "dam*******2005@interia.pl",
      rank: 28,
      total_points: 46,
    },
    {
      email: "ban******na5@gmail.com",
      rank: 29,
      total_points: 46,
    },
    {
      email: "jus******olna@gmail.com",
      rank: 30,
      total_points: 46,
    },
    {
      email: "mar*******ewicz@wp.pl",
      rank: 31,
      total_points: 41,
    },
  ],
  [
    {
      email: "ch****r07@op.pl",
      rank: 1,
      total_points: 371,
    },
    {
      email: "pau*******obel@onet.pl",
      rank: 2,
      total_points: 216,
    },
    {
      email: "e**i@o2.pl",
      rank: 3,
      total_points: 211,
    },
    {
      email: "il*****007@o2.pl",
      rank: 4,
      total_points: 206,
    },
    {
      email: "nat******rol7@gmail.com",
      rank: 5,
      total_points: 206,
    },
    {
      email: "ja****t06@op.pl",
      rank: 6,
      total_points: 206,
    },
    {
      email: "bn****35@o2.pl",
      rank: 7,
      total_points: 201,
    },
    {
      email: "os****ska@interia.pl",
      rank: 8,
      total_points: 196,
    },
    {
      email: "pr*****kas@gmail.com",
      rank: 9,
      total_points: 186,
    },
    {
      email: "ke****23@interia.pl",
      rank: 10,
      total_points: 181,
    },
    {
      email: "i**2@interia.eu",
      rank: 11,
      total_points: 181,
    },
    {
      email: "jul******duch@gmail.com",
      rank: 12,
      total_points: 176,
    },
    {
      email: "ban******na5@gmail.com",
      rank: 13,
      total_points: 146,
    },
    {
      email: "li****us1@gmail.com",
      rank: 14,
      total_points: 141,
    },
    {
      email: "mart********ka.95@wp.pl",
      rank: 15,
      total_points: 136,
    },
    {
      email: "mar******569@gmail.com",
      rank: 16,
      total_points: 131,
    },
    {
      email: "ma****24@wp.pl",
      rank: 17,
      total_points: 131,
    },
    {
      email: "l.****cka@o2.pl",
      rank: 18,
      total_points: 131,
    },
    {
      email: "awa*******lams@gmail.com",
      rank: 19,
      total_points: 126,
    },
    {
      email: "mi*****lu2@o2.pl",
      rank: 20,
      total_points: 126,
    },
    {
      email: "sz*****aba@gmail.com",
      rank: 21,
      total_points: 126,
    },
    {
      email: "mic******h.pl@gmail.com",
      rank: 22,
      total_points: 126,
    },
    {
      email: "ser*******zina@gmail.com",
      rank: 23,
      total_points: 121,
    },
    {
      email: "kor*******teusz@wp.pl",
      rank: 24,
      total_points: 121,
    },
    {
      email: "ol*****a022@gmail.com",
      rank: 25,
      total_points: 116,
    },
    {
      email: "just*********takl1c@gmail.com",
      rank: 26,
      total_points: 111,
    },
    {
      email: "aks*******enata@wp.pl",
      rank: 27,
      total_points: 111,
    },
    {
      email: "g***szc@wp.pl",
      rank: 28,
      total_points: 111,
    },
    {
      email: "nata********bedz@gmail.com",
      rank: 29,
      total_points: 106,
    },
    {
      email: "Wero********3002@wp.pl",
      rank: 30,
      total_points: 106,
    },
    {
      email: "draw*********am121@gmail.com",
      rank: 31,
      total_points: 96,
    },
  ],
];

export default rankingInfo;
