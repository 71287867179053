import React, { useEffect } from "react";
import "./App.scss";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/appState";
import { Dispatch } from "redux";

import { fetchMe } from "connections/me";
import { setMeAction, unsetMeAction } from "redux/me/actions";
import { setLoggedInAction } from "redux/auth/actions";
import PageLoader from "components/app/components/PageLoader";
import ControllerRouter from "components/routers/ControllerRouter";
import UserRouter from "components/routers/UserRouter";
import GuestRouter from "components/routers/GuestRouter";
import { cleanLocalStorage } from "utils/helpers/localStorage";
import { useLocation } from "react-router-dom";
import { logout, logoutSession } from "connections/logout";

type AppActionsTypes = ReturnType<typeof setLoggedInAction> | ReturnType<typeof setMeAction>;

const App: React.FC = () => {
  const dispatch = useDispatch<Dispatch<AppActionsTypes>>();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await fetchMe();
      if (response.status === "success") {
        dispatch(setLoggedInAction(true));
        dispatch(setMeAction(response.data));
      } else {
        const responseLogout = await logout();
        if (responseLogout.status === "success") {
          dispatch(unsetMeAction());
          dispatch(setLoggedInAction(false));
          cleanLocalStorage();
          await logoutSession();
        } else {
          const responseAuthLogout = await logoutSession();
          if (responseAuthLogout.status === "success") {
            dispatch(unsetMeAction());
            dispatch(setLoggedInAction(false));
            cleanLocalStorage();
          }
        }
      }
    })();
  }, [dispatch]);

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const isController = useSelector((state: RootState) => state.me.info?.is_corrector);
  if (isLoggedIn === null && isController === undefined) {
    return <PageLoader />;
  } else if (isLoggedIn && isController !== undefined) {
    if (isController === true) {
      return <ControllerRouter />;
    } else {
      return <UserRouter />;
    }
  } else {
    return <GuestRouter />;
  }
};

export default App;
