import React from "react";

import { useSelector } from "react-redux";

import QuestionnaireInput from "../components/QuestionnaireInput";
import { RootState } from "redux/appState";

import ReactHtmlParser from "react-html-parser";
import { FormikHandlers } from "formik";

interface Questionnaire {
  values: object;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  handleFocus: (element: number) => void;
  handleKeyUp: (element: number) => void;
}

const Questionnaire: React.FC<Questionnaire> = ({ handleChange, handleBlur, values, handleFocus, handleKeyUp }) => {
  const text = useSelector((state: RootState) => state.questionnaire.data?.text);

  const temp = text.map((el) => {
    if (typeof el !== "number") {
      return el.toString().replace(/(?:\r\n|\r|\n)/g, "<br/>");
    }
    return el;
  });

  const content = temp.map((element) => {
    if (typeof element === "number") {
      return (
        <QuestionnaireInput
          id={element}
          key={element}
          name={`${element}`}
          value={values[`${element}`]}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          handleKeyUp={handleKeyUp}
        />
      );
    }
    return ReactHtmlParser(element);
  });
  return <>{content}</>;
};

export default Questionnaire;
