export enum AppStatus {
  LOGGED_IN = "LOGGED_IN",
  QUESTIONNAIRE_IN_PROGRESS = "QUESTIONNAIRE_IN_PROGRESS",
  LOGGED_OUT = "LOGGED_OUT",
}

export enum QuestionnaireStatus {
  SEND = "SEND",
  NOT_STARTED = "NOT_STARTED",
}

export enum UserStatus {
  HAS_ENTRY = "has_entry",
  SPENT_TRIES = "SPENT_TRIES",
  CAN_FILL = "CAN_FILL",
}

export enum LocalStorageItems {
  TOKEN = "TOKEN",
  APP_STATUS = "APP_STATUS",
  QUESTIONNAIRE_STATUS = "QUESTIONNAIRE_STATUS",
  REFERRER_CODE = "REFERRER_CODE",
  ANSWERS = "ANSWERS",
  TIMESTAMPS = "TIMESTAMPS",
  EMAIL = "EMAIL",
  RESIGN = "RESIGN",
  SEARCH_USER_ID = "SEARCH_USER_ID",
}
