import React from "react";
import "./components/scss/bulmaStyles.scss";

import store from "./redux/store";
import { render } from "react-dom";
import { Provider } from "react-redux";

import App from "App";
import { Router } from "react-router-dom";
import { history } from "utils/history";
import { ToastContainer } from "react-toastify";

render(
  <Router history={history}>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} closeOnClick />
    </Provider>
  </Router>,
  document.getElementById("root")
);
