import React from "react";
import { polishPlural } from "utils/helpers/polishPluar";

interface ScoreSection {
  currentEdition?: boolean;
  editionName: string;
  allTriedCount: number | undefined;
  acceptedTriedCount: number | undefined;
  totalPoints: number | undefined;
  entryPoints: number | undefined;
  friendPoints: number | undefined;
  rank: number | undefined;
  startDate: string;
  endDate: string;
}

const ScoreSection: React.FC<ScoreSection> = ({
  currentEdition = false,
  editionName,
  totalPoints,
  acceptedTriedCount,
  allTriedCount,
  entryPoints,
  friendPoints,
  rank,
  startDate,
  endDate,
}) => {
  return (
    <article className="dashboard-container__article">
      <div className="dashboard-container__article--title">
        <h1>
          {currentEdition
            ? `Twoje wyniki w aktualnej edycji ${editionName}`
            : `Twoje wyniki w edycji: "${editionName}"`}
          {` (${startDate} - ${endDate})`}
        </h1>
      </div>
      <div className="dashboard-container__article__grid">
        <div>
          <div className="dashboard-container__article--number">{acceptedTriedCount} / 20</div>
          <div className="dashboard-container__article--bold">
            {`${polishPlural("Rozwiązana", "Rozwiązane", "Rozwiązanych", acceptedTriedCount)}
            ${polishPlural("zagadka", "zagadki", "zagadek", acceptedTriedCount)}`}
          </div>
          <div>Liczba wszystkich prób: {allTriedCount}</div>
        </div>
        {currentEdition ? (
          <div>
            <div className="dashboard-container__article--number">{totalPoints}</div>
            <div className="dashboard-container__article--bold">
              {polishPlural("Punkt", "Punkty", "Punktów", entryPoints)}
            </div>
            <div>w poczekalni</div>
            <div>{`${entryPoints} ${polishPlural(
              "punkt",
              "punkty",
              "punktów",
              entryPoints
            )} za rozwiązane zagadki`}</div>
            <div>{`${friendPoints} ${polishPlural(
              "punkt",
              "punkty",
              "punktów",
              friendPoints
            )} za polecenia agenetów`}</div>
          </div>
        ) : (
          <div>
            <div className="dashboard-container__article--number">{totalPoints}</div>
            <div className="dashboard-container__article--bold">{`${polishPlural(
              "Punkt",
              "Punkty",
              "Punktów",
              totalPoints
            )}`}</div>
            <div>{`${entryPoints} ${polishPlural(
              "punkt",
              "punkty",
              "punktów",
              entryPoints
            )} za rozwiązane zagadki`}</div>
            <div>{`${friendPoints} ${polishPlural(
              "punkt",
              "punkty",
              "punktów",
              friendPoints
            )} za polecenia agenetów`}</div>
          </div>
        )}
        {currentEdition ? (
          <div>
            <div className="dashboard-container__article--number">{rank}</div>
            <div className="dashboard-container__article--bold">Miejsce</div>
            <div>w prognozowanym rankingu obecnej edycji</div>
          </div>
        ) : (
          <div>
            <div className="dashboard-container__article--number">{rank}</div>
            <div className="dashboard-container__article--bold">Miejsce</div>
            <div>zajęte w tamtej edycji</div>
          </div>
        )}
      </div>
    </article>
  );
};

export default ScoreSection;
