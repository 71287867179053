import React from "react";
import "./CheckboxWithError.scss";
import { FormikHandlers } from "formik";

interface CheckboxWithErrorProps {
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  touched?: boolean | undefined;
  error?: string | undefined;
  name: string;
  value: boolean;
}

const CheckboxWithError: React.FC<CheckboxWithErrorProps> = ({
  name,
  handleChange,
  handleBlur,
  children,
  touched,
  error,
  value,
}) => {
  return (
    <div className="checkbox checkbox-with-errors">
      <label>
        <input type="checkbox" name={name} checked={value} onChange={handleChange} onBlur={handleBlur} />
        {children}
      </label>
      <label>{touched && error}</label>
    </div>
  );
};

export default CheckboxWithError;
