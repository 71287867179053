import React from "react";
import "./InfoPage.scss";
import logoNCBR from "../../img/logoNCBR.png";
import logoUE from "../../img/logoUE.png";
import logoPL from "../../img/logoPL.png";
import logoFE from "../../img/logoFE.png";

const ProjectPage: React.FC = () => {
  return (
    <div className="info-container__background">
      <main className="info-container">
        <div className="info-container__title">
          <h1>O projekcie</h1>
        </div>
        <section className="info-container__section">
          <p className="info-container__paragraph">
            Olsztyński Park Naukowo-Technologiczny – to tu, wśród rozkosznego śpiewu ptaków, szumu soczyście zielonych
            drzew, rytmicznego stukania niestrudzonych programistów w klawiaturę i pełnych determinacji westchnięć
            natchnionych naukowców powstaje{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://goodwrite.pl">
              GoodWrite
            </a>
            . To inteligentny asystent pisania, który w całości zaopiekuje się tworzonymi przez Ciebie tekstami –
            poprawi błędy językowe i wskaże fragmenty, które mogą być niezrozumiałe dla Twojego odbiorcy.
          </p>
          <p className="info-container__paragraph">
            Narzędzie bazować będzie na algorytmach sztucznej inteligencji, uczeniu maszynowym i wnikliwej analizie
            morfosyntaktycznej. Jego pierwsza, prototypowa wersja w postaci wtyczki do przeglądarki Google Chrome oraz
            wirtualnego edytora tekstów dostępna jest na stronie{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://goodwrite.pl">
              goodwrite.pl
            </a>
            .
          </p>
          <p className="info-container__paragraph">
            Do czego wykorzystywać będziemy teksty nadesłane do nas w ramach konkursu?
          </p>
          <p className="info-container__paragraph">
            Posłużą nam one do jeszcze lepszego dopracowania algorytmów sztucznej inteligencji, które zasilą silnik
            naszego asystenta. Wykorzystywać je będziemy wyłącznie do celów badawczych (naukowych), a dane osobowe
            uczestników konkursu nie będą przez nas w żaden sposób ujawniane.
          </p>
          <div className="login-ctx__logos">
            <img className="ctx__logo" src={logoUE} alt="Logotypy unijne" />
            <img className="ctx__logo" src={logoFE} alt="Logotypy unijne" />
            <img className="ctx__logo" src={logoPL} alt="Logotypy unijne" />
            <img className="ctx__logo" src={logoNCBR} alt="Logotypy unijne" />
          </div>
          <p className="info-container__paragraph info-container__paragraph--small">
            Projekt współfinansowany przez Unię Europejską ze środków Funduszu Europejskich w ramach Programu
            Inteligentny Rozwój. Projekt realizowany w ramach konkursu Narodowego Centrum Badań i Rozwoju: Szybka
            Ścieżka. Więcej informacji:{" "}
            <a href="https://goodwrite.pl/o-projekcie/" target="_blank" rel="noopener noreferrer">
              https://goodwrite.pl/o-projekcie/
            </a>
          </p>
        </section>
      </main>
    </div>
  );
};

export default ProjectPage;
