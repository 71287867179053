import { EditionsInfo } from "connections/models/credentials";
import React from "react";

interface EditionListProps {
  handleChange: (number) => void;
  editionsInfo: EditionsInfo[];
  allOption?: boolean;
  seperatorId?: number;
  withoutFirst?: boolean;
}

const EditionList: React.FC<EditionListProps> = ({
  editionsInfo,
  handleChange,
  allOption = false,
  seperatorId = 0,
  withoutFirst = false,
}) => {
  const Options: (JSX.Element | null)[] = editionsInfo.map((edition, index) => {
    if (withoutFirst && index === 0) {
      return null;
    }
    if (edition.id >= seperatorId) {
      return (
        <option key={edition.id} value={edition.id}>
          {edition.name}
        </option>
      );
    }
    return null;
  });
  return (
    <div className="select">
      <select
        name="editions"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => handleChange(e.target.value)}
      >
        {allOption ? (
          <option value="0" key="0">
            Wszystkie
          </option>
        ) : (
          ""
        )}
        {Options}
      </select>
    </div>
  );
};

export default EditionList;
