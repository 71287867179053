import React, { useEffect, useState } from "react";
import "./CheckedQuestionnairesPage";
import CheckedQuestionnarie from "./components/CheckedQuestionnarie";
import { CheckedEntryInfo } from "connections/models/credentials";
import { getCheckedEntries } from "connections/controller";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";

const CheckedQuestionnariesPage: React.FC = () => {
  const [checkedEntries, setCheckedEntries] = useState<CheckedEntryInfo[]>([]);
  const email = useSelector((state: RootState) => state.me.info?.email);

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getCheckedEntries();
      if (response.status === "success") {
        setCheckedEntries(response.data);
      }
    })();
  }, []);

  return (
    <>
      <header className="header-ctx">
        <div className="header-ctx__flex--header header-ctx__flex">Zalogowany jako: {email}</div>
      </header>
      <main className="controller-dashboard-container">
        <section className="controller-dashboard-container__section">
          <header className="controller-dashboard-container__header">Sprawdzone ankiety:</header>
          <div className="content">
            <table className="table controller-dashboard-container__table">
              <thead>
                <tr>
                  <th>ID ankiety</th>
                  <th>ID tekstu | Wariant</th>
                  <th>Korektor</th>
                  <th>Data sprawdzenia</th>
                  <th>Podejrzyj ankietę</th>
                </tr>
              </thead>
              <tbody>
                {checkedEntries.map((checkedEntry) => (
                  <CheckedQuestionnarie
                    key={checkedEntry.id}
                    id={checkedEntry.id}
                    variant={checkedEntry.variant}
                    idText={checkedEntry.text_id}
                    isCorrect={checkedEntry.is_correct}
                    checkedBy={checkedEntry.checked_by}
                    checkedAt={checkedEntry.checked_at}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {checkedEntries.length === 0 && <div>Nie ma ankiet czekających na sprawdzenie</div>}
        </section>
      </main>
    </>
  );
};

export default CheckedQuestionnariesPage;
