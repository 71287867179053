import client from "./client";
import Response from "./envelope";
import { PatchMe, PersonalQuestionnaire, ReferrerData, StatsResponse } from "./models/credentials";
import { Me } from "redux/me/state";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";

export async function fetchMe(): Promise<Response<Me>> {
  try {
    const response = await client.get<Me>("/user/me/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function fetchStats(): Promise<Response<StatsResponse>> {
  try {
    const response = await client.get<StatsResponse>("/user/stats/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function postPersonalQuestionnaire(
  credentials: PersonalQuestionnaire
): Promise<Response<string, PersonalQuestionnaire>> {
  try {
    const response = await client.post<PersonalQuestionnaire>("/user/questionnaire/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getReferrer(id: string): Promise<Response<string, ReferrerData>> {
  try {
    const response = await client.get<ReferrerData>(`/user/?code=${id}`);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function patchMe(credentials: PatchMe): Promise<Response<string, PatchMe>> {
  try {
    const response = await client.patch<PatchMe>("/rest-auth/user/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return { data: error.response, status: "failure" };
  }
}

export async function setPermissions(accepted_newsletter: boolean): Promise<Response<string>> {
  try {
    const response = await client.patch("/user/permissions/", { accepted_newsletter });
    return parseToSuccessResponse(response);
  } catch (error) {
    return { data: error.response, status: "failure" };
  }
}

export async function deleteUser(): Promise<Response<string>> {
  try {
    const response = await client.delete("/user/permissions/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return { data: error.response, status: "failure" };
  }
}
