/* eslint-disable no-irregular-whitespace */
import React from "react";
import "./RulesPage.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="rules-container__background">
      <main className="rules-container">
        <div className="rules-container__title">
          <h1>POLITYKA PRYWATNOŚCI</h1>
        </div>
        <div className="content">
          <ol className="rules-container__ol" type="I">
            <li className="rules-container__li">
              <a href="#1">Administrator danych</a>
            </li>
            <li className="rules-container__li">
              <a href="#2">Rodzaj i sposób przetwarzanych danych</a>
            </li>
            <li className="rules-container__li">
              <a href="#3">Cel i czas przetwarzania danych</a>
            </li>
            <li className="rules-container__li">
              <a href="#4">Przekazywanie danych</a>
            </li>
            <li className="rules-container__li">
              <a href="#5">Prawa użytkowników w stosunku do przetwarzanych danych</a>
            </li>
            <li className="rules-container__li">
              <a href="#6">Cel wykorzystywania plików cookies oraz zasady korzystania z nich</a>
            </li>
            <li className="rules-container__li">
              <a href="#7">Czym są pliki cookies</a>
            </li>
            <li className="rules-container__li">
              <a href="#8">W ramach niniejszej strony internetowej stosowane są następujące rodzaje plików cookies:</a>
            </li>
            <li className="rules-container__li">
              <a href="#9">W jaki sposób pliki cookies dopasowują stronę internetową do potrzeb użytkownika</a>
            </li>
            <li className="rules-container__li">
              <a href="#10">Sposób zarządzania plikami cookies przez użytkowników.</a>
            </li>
          </ol>
        </div>
        <div className="rules-container__jump-to" id="1"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Administrator danych</div>
          <div className="content">
            <p>
              Administratorem danych osobowych jest Ermlab sp. z o.o., właściciel marki GoodWrite.pl, z siedzibą w
              Olsztynie, przy ul. Trylińskiego 16, NIP: 7393868502. Administrator wyznaczył inspektora ochrony danych, z
              którym można skontaktować się poprzez e-mail: kwitkowska@ermlab.com w każdej sprawie dotyczącej
              przetwarzania danych osobowych użytkowników.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="2"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Rodzaj i sposób przetwarzanych danych</div>
          <div className="content">
            <p>Przetwarzane będą dwa rodzaje danych:</p>
            <ol className="rules-container__ol" type="a">
              <li className="rules-container__li">
                dane przekazane przez użytkownika w formularzu osobowym wypełnianym po założeniu konta,
              </li>
              <li className="rules-container__li">
                dane, które są zbierane w ramach korzystania ze strony internetowej i jej funkcjonalności, w tym
                zapisywanych w plikach cookies, które są instalowane na niniejszej stronie internetowej wyłącznie przez
                Administratora danych.
              </li>
            </ol>
            <p>
              Dane przekazane przez użytkowników w formularzu osobowym zostaną zanonimizowane. Przetwarzane będą
              wyłącznie przez Administratora w celach badawczo-rozwojowych, tj. na potrzeby prac prowadzonych w ramach
              projektu nr POIR.01.01.01- 00-1128/18-01 Szybka ścieżka NCBR, pn. Technologia kontekstowego rozumienia
              języka pisanego na potrzeby poprawy błędów oraz automatycznej oceny zrozumiałości tekstu.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="3"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Cel i czas przetwarzania danych</div>
          <div className="content">
            <p>
              Dane osobowe użytkowników będą przetwarzane w celu realizacji konkursu (w tym wyłonienia osób nagrodzonych
              i wręczenia nagród), w tym celu zapewnienia możliwości ustalenia lub dochodzenia ewentualnych roszczeń lub
              obrony przed takimi roszczeniami przez Administratora, a także na potrzeby prac badawczo-rozwojowych
              prowadzonych w ramach projektu nr POIR.01.01.01- 00-1128/18-01 Szybka ścieżka NCBR, pn. Technologia
              kontekstowego rozumienia języka pisanego na potrzeby poprawy błędów oraz automatycznej oceny zrozumiałości
              tekstu. Podstawą prawną przetwarzania danych jest zgoda osoby, której dane dotyczą (art. 6 ust. 1 lit. a)
              Rozporządzenia) oraz prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit f) Rozporządzenia.
            </p>
            <p>
              Dane osobowe użytkowników będą przetwarzane przez okres istnienia prawnie uzasadnionego interesu
              Administratora, chyba że użytkownik wyrazi sprzeciw wobec przetwarzania jego danych osobowych lub cofnie
              zgodę na przetwarzanie jego danych osobowych. Okres przetwarzania danych osobowych może zostać każdorazowo
              przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie niezbędne dla
              dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Administratora. Po tym
              okresie dane będą przetwarzane jedynie w zakresie i przez czas wymagany przepisami prawa.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="4"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Przekazywanie danych</div>
          <div className="content">
            <p>
              Dane osobowe użytkowników zgodnie z obowiązującym mogą być przekazywane wyłącznie podmiotom świadczącym
              usługi na rzecz Administratora, wyłącznie w związku z realizacją Konkursu i/lub realizacją prac
              badawczo-rozwojowych w ramach projektu nr POIR.01.01.01- 00-1128/18-01 Szybka ścieżka NCBR, pn.
              Technologia kontekstowego rozumienia języka pisanego na potrzeby poprawy błędów oraz automatycznej oceny
              zrozumiałości tekstu, m.in. podmiotom świadczącym usługi księgowe, dostawcom systemów informatycznych i
              usług IT, podmiotom świadczącym usługi prawne, podmiotom świadczącym usługi archiwizacji dokumentów. Dane
              osobowe użytkowników mogą być także przekazywane uprawnionym organom w zakresie wymaganym przez
              obowiązujące przepisy prawa.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="5"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Prawa użytkowników w stosunku do przetwarzanych danych</div>
          <div className="content">
            <p>Uczestnikowi przysługuje prawo:</p>
            <ol className="rules-container__ol" type="a">
              <li className="rules-container__li">
                dostępu do treści danych oraz żądania ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do
                przenoszenia danych osobowych, wniesienia sprzeciwu względem przetwarzania danych oraz prawo do
                wycofania zgody w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej wycofaniem.
              </li>
              <li className="rules-container__li">
                wniesienia sprzeciwu względem przetwarzania danych osobowych z przyczyn związanych z jego szczególną
                sytuacją.
              </li>
              <li className="rules-container__li">
                wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, gdy uzna, że
                przetwarzanie danych osobowych narusza przepisy Rozporządzenia.
              </li>
            </ol>
            <p>Zgłoszenia można wysłać mailowo na adres: kwitkowska@ermlab.com.</p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="6"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Polityka cookies</div>
          <div className="rules-container__header">
            Cel wykorzystywania plików cookies oraz zasady korzystania z nich
          </div>
          <div className="content">
            <p>
              Pliki cookies używane na niniejszej stronie internetowej pozwalają jej użytkownikom przede wszystkim na
              bezpieczne i jak najlepsze korzystanie z niej. Pliki cookies umożliwiają między innymi logowanie do
              serwisu oraz odpowiednią personalizację wyświetlanych na stronie dokumentów i informacji. Niniejsza
              Polityka dotycząca plików cookies wyjaśnia, czym są pliki cookies, jakie są reguły ich stosowania w
              serwisie, oraz jakie płyną korzyści z ich zastosowania.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="7"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Czym są pliki cookies</div>
          <div className="content">
            <p>
              Cookies są to małe pliki tekstowe, wysyłane i przechowywane na komputerze, smartfonie czy innych
              urządzeniach, z których użytkownik łączy się z Internetem. Pliki te są pomocne, gdyż umożliwiają serwisowi
              rozpoznać urządzenie użytkownika i odpowiednio się wyświetlić. W niniejszej platformie pliki cookies są
              wykorzystywane, m.in by dostosować układ strony internetowej do preferencji użytkowników, ułatwić
              nawigację po stronach oraz zapewnić skuteczność procedur bezpieczeństwa. Niektóre z tych funkcji są
              realizowane również przez inne rozwiązania technologiczne; niniejsza polityka dotycząca plików cookies
              odnosi się zarówno do typowych plików cookies, jak i innych mechanizmów spełniających ten sam cel.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="8"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">
            W ramach niniejszej strony internetowej stosowane są następujące rodzaje plików cookies:
          </div>
          <div className="content">
            <ol className="rules-container__ol" type="a">
              <li className="rules-container__li">
                niezbędne pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach serwisu, np.
                uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach serwisu;
              </li>
              <li className="rules-container__li">
                pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie
                uwierzytelniania w ramach serwisu;
              </li>
              <li className="rules-container__li">
                wydajnościowe pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania z niniejszej
                strony internetowej;
              </li>
              <li className="rules-container__li">
                funkcjonalne pliki cookies, umożliwiające &quot;zapamiętanie&ldquo; wybranych przez użytkownika ustawień
                i personalizację interfejsu użytkownika, np. w zakresie rozmiaru czcionki, wyglądu strony internetowej
                itp.
              </li>
            </ol>
          </div>
        </section>
        <div className="rules-container__jump-to" id="9"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">
            W ramach niniejszej strony internetowej stosowane są następujące rodzaje plików cookies:
          </div>
          <div className="content">
            <p>
              Cookies ułatwiają proces dostosowania zawartości niniejszej strony internetowej do oczekiwań użytkowników.
              Analityczne pliki cookies wykorzystywane są w celu oceny statystyk wejść na niniejszą stronę internetową,
              bo dzięki nim wiadomo, jakie są preferencje i oceny użytkowników, co pozwala nam ulepszać nasze serwisy
              internetowe.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="10"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">Sposób zarządzania plikami cookies przez użytkowników.</div>
          <div className="content">
            <p>
              Większość używanych przeglądarek, zarówno na komputerach, jak i smartfonach czy innych urządzeniach,
              domyślnie akceptuje pliki cookies. W przypadku chęci zmiany domyślnych ustawień można to zrobić za pomocą
              ustawień w przeglądarce. W przypadku problemów z ustawieniem zmian należy skorzystać z opcji „Pomoc” w
              menu używanej przeglądarki.
            </p>
          </div>
        </section>
        <div className="rules-container__jump-to" id="11"></div>
        <section className="rules-container__section">
          <div className="rules-container__header">
            Informujemy, iż wiele plików cookies pozwala na wygodniejsze i efektywniejsze korzystanie z niniejszej
            strony internetowej. Wyłączenie ich może spowodować nieprawidłowe wyświetlanie się serwisu w przeglądarce.
          </div>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
