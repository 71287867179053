import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RegistrationPage from "components/registration-page/RegistrationPage";
import SendEmailPage from "components/send-email-page/SendEmailPage";
import ConfirmEmailPage from "components/confirm-email-page/ConfirmEmailPage";
import ErrorPage from "components/error-page/ErrorPage";
import ResetPasswordPage from "components/reset-password-page/ResetPasswordPage";
import RulesPage from "components/rules-page/RulesPage";
import StartingPage from "components/starting-page/StartingPage";
import DetectivePage from "components/info-pages/DetectivePage";
import ProjectPage from "components/info-pages/ProjectPage";
import Footer from "components/app/Footer";
import GuestNavBar from "components/app/GuestNavBar";
import PrivacyPolicy from "components/rules-page/PrivacyPolicy";
import FailedEmailPage from "components/failed-confirm-page/FailedEmailPage";

function GuestRouter(): JSX.Element {
  return (
    <div className="footer-setter footer-setter--background">
      <GuestNavBar />
      <Switch>
        <Route exact path="/rejestracja" component={RegistrationPage} />
        <Route exact path="/wyslano-email" component={SendEmailPage} />
        <Route path="/potwierdzono-email" component={ConfirmEmailPage} />
        <Route exact path="/o-detektywach" component={DetectivePage} />
        <Route exact path="/o-projekcie" component={ProjectPage} />
        <Route exact path="/regulamin" component={RulesPage} />
        <Route exact path="/nieudane-potwierdzenie" component={FailedEmailPage} />
        <Route exact path="/polityka-prywatnosci" component={PrivacyPolicy} />
        <Route path="/reset-hasla/:uid/:token/" component={ResetPasswordPage} />
        <Route path="/reset-hasła/:uid/:token/" component={ResetPasswordPage} />
        <Route exact path="/:referrerCode" component={StartingPage} />
        <Route exact path={["/blad", "/blad/:errorNumber"]} component={ErrorPage} />
        <Route exact path={["/", "/:referrerCode"]} component={StartingPage} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </div>
  );
}

export default GuestRouter;
