export const parseToSuccessResponse = <T>(response): { data: T; status: "success" } => {
  return { data: response.data, status: "success" };
};

export const parseToSuccessResponseResults = <T>(response): { data: T; status: "success" } => {
  return { data: response.data.results, status: "success" };
};

export const parseToErrorResponse = (error): { data: any; status: "failure" } => {
  return { data: error.response.data, status: "failure" };
};
