import React, { ReactText } from "react";
import "./ResetPasswordPage.scss";

import { history } from "utils/history";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputWithError from "components/app/components/forms/InputWithError";
import { toast } from "react-toastify";
import { resetPassword } from "connections/auth";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object({
  password: Yup.string().min(6, "Hasło jest za krótkie").required("Musisz podać hasło"),
  confirmPassword: Yup.string()
    .min(6, "Hasło jest za krótkie")
    .required("Powtórz hasło")
    .oneOf([Yup.ref("password"), ""], "Hasła do siebie nie pasują"),
});

interface ParamTypes {
  uid: string;
  token: string;
}

const ResetPasswordPage: React.FC = () => {
  const { uid, token } = useParams<ParamTypes>();
  const successToast = (): ReactText => toast.success("Zmieniono hasło");
  const { values, handleSubmit, handleChange, handleBlur, setErrors, errors, touched } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      const data = {
        new_password1: values.password,
        new_password2: values.confirmPassword,
        uid,
        token,
      };
      const response = await resetPassword(data);
      if (response.status === "success") {
        successToast();
        history.push("/");
      } else {
        if (response.data.detail) {
          setErrors({ confirmPassword: response.data.detail });
        }
        if (response.data.token) {
          setErrors({ confirmPassword: response.data.token[0] });
        }
      }
    },
    validationSchema,
  });
  return (
    <div className="settings-container__background">
      <form onSubmit={handleSubmit}>
        <main className="settings-container">
          <div className="settings-container__personal-data">
            <div className="settings-container__title">Nowe hasło</div>
            <InputWithError
              className={"input settings-container__input"}
              type="password"
              value={values.password}
              placeholder="Podaj nowe hasło"
              name={"password"}
              error={errors.password}
              touched={touched.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          <div className="settings-container__personal-data">
            <div className="settings-container__title">Powtórz nowe hasło</div>
            <InputWithError
              className={"input settings-container__input"}
              type="password"
              value={values.confirmPassword}
              placeholder="Powtórz hasło"
              name={"confirmPassword"}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          <button type="submit" className="button is-success settings-container__button">
            Zmień hasło
          </button>
          <button
            type="button"
            className="button is-danger settings-container__button"
            onClick={(): void => {
              history.push("/");
            }}
          >
            Anuluj
          </button>
        </main>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
