import React, { Dispatch, useState } from "react";
import "./NavBar.scss";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "redux/appState";
import { AppStatus } from "utils/enums";
import { setLoggedInAction } from "redux/auth/actions";
import { history } from "utils/history";

import ClozeLogo from "../../img/ClozeLogo.png";
import { logout, logoutSession } from "connections/logout";
import { cleanLocalStorage } from "utils/helpers/localStorage";
import { unsetMeAction } from "redux/me/actions";

type NavbarActionsTypes = ReturnType<typeof setLoggedInAction | typeof unsetMeAction>;

const NavBar: React.FC = () => {
  const dispatch = useDispatch<Dispatch<NavbarActionsTypes>>();
  const [isExpanded, setIsExpanded] = useState(false);
  const appStatus = useSelector((state: RootState) => state.appStatus.status);

  const handleClick = (): void => {
    setIsExpanded((previousValue) => !previousValue);
  };

  const handleLogout = async (): Promise<void> => {
    const response = await logout();
    if (response.status === "success") {
      dispatch(unsetMeAction());
      dispatch(setLoggedInAction(false));
      cleanLocalStorage();
      await logoutSession();
      history.push("/");
    } else {
      const responseAuthLogout = await logoutSession();
      if (responseAuthLogout.status === "success") {
        dispatch(unsetMeAction());
        dispatch(setLoggedInAction(false));
        cleanLocalStorage();
        history.push("/");
      }
    }
  };

  if (appStatus === AppStatus.QUESTIONNAIRE_IN_PROGRESS) {
    return <div></div>;
  } else {
    return (
      <div className="container-navbar">
        <nav className="navbar is-info" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <div className="navbar-item navbar-item__logo">
              <Link to="/strona-glowna">
                <img src={ClozeLogo} alt="Językowi detektywi" />
              </Link>
            </div>
            <div
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={handleClick}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${isExpanded ? "is-active" : ""}`}>
            <div className="navbar-end">
              <Link to="/o-detektywach" className="navbar-item" onClick={handleClick}>
                O konkursie
              </Link>
              <Link to="/ranking" className="navbar-item" onClick={handleClick}>
                Ranking
              </Link>
              <div className="container-navbar__logout button is-danger" onClick={handleLogout}>
                Wyloguj się
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default NavBar;
