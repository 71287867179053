import React, { AllHTMLAttributes } from "react";
import "./InputWithError.scss";
import { FormikHandlers } from "formik";

interface InputWithErrorProps extends AllHTMLAttributes<HTMLInputElement> {
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  touched?: boolean;
  error?: string;
  className?: string;
  name: string;
  placeholder: string;
  type: string;
}

const InputWithError: React.FC<InputWithErrorProps> = ({
  name,
  placeholder,
  type,
  handleChange,
  handleBlur,
  touched = false,
  error,
  value,
  className,
  ...props
}) => {
  return (
    <div className="input-with-error">
      <input
        className={className}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      <label>{touched && error}</label>
    </div>
  );
};

export default InputWithError;
