import React, { useEffect, Dispatch } from "react";
import "./StartingPage.scss";

import { Link as ScrollLink } from "react-scroll";

import logo from "../../img/LogoPoziom.png";
import LoginForm from "./components/LoginForm";
import { history } from "utils/history";
import { setAppStatusAction } from "redux/appStatus/actions";
import { useDispatch } from "react-redux";
import { AppStatus, LocalStorageItems } from "utils/enums";
import logoNCBR from "../../img/logoNCBR.png";
import logoUE from "../../img/logoUE.png";
import logoPL from "../../img/logoPL.png";
import logoFE from "../../img/logoFE.png";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";

type RegistrationActionsTypes = ReturnType<typeof setAppStatusAction>;

const StartingPage: React.FC = () => {
  const dispatch = useDispatch<Dispatch<RegistrationActionsTypes>>();
  const location = useLocation();

  useEffect(() => {
    dispatch(setAppStatusAction(AppStatus.LOGGED_OUT));
    const referrerCode = queryString.parse(location.search) as { promo_code: string; fbclid: string };
    const uuidRegExp = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
    if (uuidRegExp.test(referrerCode.promo_code)) {
      localStorage.setItem(LocalStorageItems.REFERRER_CODE, referrerCode.promo_code);
    }
  }, [dispatch, location.search]);

  const registerRedirect = (): void => {
    history.push("/rejestracja");
  };

  const navigateToExternalUrl = (url: string, openNewTab = false) =>
    openNewTab
      ? window.open(url, "_blank", "noopener,noreferrer")
      : (window.location.href = url);

  const MySwal = withReactContent(Swal);

  MySwal.fire({
    html:
      <div className="px-4 pt-4">
        <div className="mb-5">
          <strong>
            Konkurs zakończył się 25.12.2020 i na ten moment nie są planowane kolejne edycje.
          </strong>
        </div>
        Efekty pracy Językowych Detektywów to nowe, udoskonalone algorytmy inteligentnego korektora tekstów <a href="https://goodwrite.pl/" target="_blank" rel="noopener noreferrer">GoodWrite.pl.</a>
      </div>,
    confirmButtonText: "Sprawdź, co wspólnie stworzyliśmy!",
  }).then(() => {
    navigateToExternalUrl("https://goodwrite.pl/", true)
  });

  const Contest: JSX.Element = (
    <main className="detective-container__main">
      <div className="detective-container__header ">
        <h1 className="detective-container__header--white-on-desktop">
          Jak wziąć udział w konkursie i wygrać nagrody?
        </h1>
      </div>
      <section>
        <div>
          <div className="detective-container__header">
            <h1 className="detective-container__header--green">Krok 1</h1>
          </div>
          <p>
            Załóż konto, powiedz nam kilka słów o sobie i włącz się w rozwikłanie największej zagadki kryminalnej
            Warmii! Rozpocznij pierwsze śledztwo i odkryj w sobie wewnętrznego Sherlocka. Uzupełnij otrzymany tekst tak,
            by ponownie tworzył spójną logicznie całość. Już teraz pierwsze punkty trafią do poczekalni Twoich wyników!
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className="detective-container__header">
            <h1 className="detective-container__header--green">Krok 2</h1>
          </div>
          <p>
            Rozwiązując językowe śledztwa, pamiętaj, że od III edycji{" "}
            <b>dodatkowo nagradzamy sumiennych i superskutecznych detektywów</b>! Każdy z agentów otrzyma ekstrapunkty
            za średnią poprawność rozwikłanych zagadek. Obliczając, ile powinna wynosić ta premia, uwzględnimy średnią
            liczbę odpowiedzi zaakceptowanych przez naszych wnikliwych weryfikatorów. Pod lupę weźmiemy każdą z zagadek
            detektywów – również te, za które nie otrzymali oni stardardowych punktów.
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className="detective-container__header">
            <h1 className="detective-container__header--green">Krok 3</h1>
          </div>
          <p>
            Rozwiąż łącznie <b>3 zagadki, by aktywować swoją licencję detektywa</b>. Nasi licencjonowani detektywi mają
            supermoce – mogą werbować do zabawy swoich znajomych i zdobywać dodatkowe punkty. Jeśli jesteście zgraną
            ekipą, to już nie możemy się doczekać, aż zobaczymy efekty Waszych śledztw i masę punktów na Waszych
            kontach!
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className="detective-container__header">
            <h1 className="detective-container__header--green">Krok 4</h1>
          </div>
          <p>
            Po zakończeniu zabawy udaj się na zasłużony odpoczynek i daj naszym ludziom pracować! Sprawdzą, czy z Twoimi
            zagadkami wszystko w porządku i obsłużą punkty, które czekają w Twojej poczekalni. Kiedy już się ze
            wszystkim uporamy, nie omieszkamy dać Ci znać, jak poszło.{" "}
            <b>Co tydzień rozdamy czytnik Kindle 10, a także 10 bonów do empik.com za 100 zł i 20 bonów za 50 zł</b>!
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className="detective-container__header">
            <h1 className="detective-container__header--green">Biorąc udział w konkursie, wspierasz naukowców!</h1>
          </div>
          <p>
            <b>Rozwiązując językowe zagadki, pomagasz naukowcom tworzyć inteligentnego asystenta pisania</b>. Wyniki
            Twoich śledztw posłużą nam do jeszcze lepszego dopracowania algorytmów sztucznej inteligencji, które zasilą
            silnik GoodWrite.pl. Dane wykorzystywać będziemy wyłącznie do celów badawczych, a informacje osobowe
            uczestników konkursu nie będą przez nas w żaden sposób ujawniane. Dowiedz się więcej o projekcie, klikając
            <a href="https://detektywi.goodwrite.pl/o-projekcie" target="_blank" rel="noopener noreferrer">
              {" "}
              TUTAJ
            </a>
            .
          </p>
        </div>
      </section>
    </main>
  );
  return (
    <div className="smooth-scroll">
      <main className="ctx">
        <div className="ctx__article">
          <article>
            <h1>Prowadź językowe śledztwa i wygrywaj nagrody!</h1>
            <div>
              <p>
                W ośrodku naukowo-badawczym w samym centrum Warmii w niewyjaśnionych okolicznościach giną słowa. Zespół
                naukowców i programistów, próbując rozwikłać kryminalną zagadkę, decyduje się poprosić o pomoc
                językowych detektywów – skoncentrowanych jak Sherlock Holmes, przebiegłych jak Joanna Chyłka i
                bezkompromisowych jak Harry Hole.
              </p>
              <p>
                Zasil ich szeregi, zbieraj punkty i co{" "}
                <b>tydzień wygrywaj czytniki Kindle 10 i bony do empik.com za 100 zł i 50 zł</b>!
              </p>
            </div>
            <div className="buttons is-hidden-desktop">
              <ScrollLink to="login" smooth={true} duration={500}>
                <button className="button is-success">Dołącz do detektywów</button>
              </ScrollLink>
              <ScrollLink to="contest-mobile" smooth={true} duration={500}>
                <button className="button is-warning">Jak działa konkurs?</button>
              </ScrollLink>
            </div>
            <div className="buttons is-hidden-touch">
              <ScrollLink to="contest-desktop" smooth={true} duration={500}>
                <button className="button is-warning">Jak działa konkurs?</button>
              </ScrollLink>
            </div>
            <div className="login-ctx__logos">
              <img className="ctx__logo" src={logoUE} alt="Logotypy unijne" />
              <img className="ctx__logo" src={logoFE} alt="Logotypy unijne" />
              <img className="ctx__logo" src={logoPL} alt="Logotypy unijne" />
              <img className="ctx__logo" src={logoNCBR} alt="Logotypy unijne" />
            </div>
          </article>
        </div>
        <div className="login-ctx has-text-centered" id="login">
          <div>
            <img src={logo} alt="logo" className="login-ctx__img" />
          </div>
          <LoginForm />
          <div className="login-ctx__block--column login-ctx__register mt-4">
            <span>
              <h1>Nie ma Cię wśród detektywów?</h1>
            </span>
            <button className="button is-success" type="submit" onClick={registerRedirect}>
              Zarejestruj się
            </button>
          </div>
          <div className="login-ctx__logos--login">
            <img className="is-hidden-desktop ctx__logo" src={logoUE} alt="Logotypy unijne" />
            <img className="is-hidden-desktop ctx__logo" src={logoFE} alt="Logotypy unijne" />
            <img className="is-hidden-desktop ctx__logo" src={logoPL} alt="Logotypy unijne" />
            <img className="is-hidden-desktop ctx__logo" src={logoNCBR} alt="Logotypy unijne" />
          </div>
        </div>
        <div className="ctx__article--footer-height tutorial-container is-hidden-desktop" id="contest-mobile">
          {Contest}
        </div>
      </main>
      <div className="ctx__article--footer-height is-hidden-touch" id="contest-desktop">
        {Contest}
      </div>
    </div>
  );
};

export default StartingPage;
