import QuestionnaireState from "./state";
import { GET_QUESTIONNAIRE, SET_ACCEPTED_QUESTIONNAIRE } from "./actions";
import { AppAction } from "redux/actions";

const initialState: QuestionnaireState = {
  data: {
    text: [],
    opinion: 0,
    accepted: null,
    created: "",
  },
};

export function questionnaireReducer(state = initialState, action: AppAction): QuestionnaireState {
  switch (action.type) {
    case GET_QUESTIONNAIRE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case SET_ACCEPTED_QUESTIONNAIRE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return state;
  }
}
