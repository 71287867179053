import React from "react";
import { FormikHandlers } from "formik";

interface SelectWithErrors {
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  touched: boolean | undefined;
  error: string | undefined;
  name: string;
  value: number;
}

const SelectWithErrors: React.FC<SelectWithErrors> = ({
  children,
  touched,
  error,
  name,
  value,
  handleBlur,
  handleChange,
}) => {
  return (
    <div className="select user-questionnaire-container__select">
      <select value={value} name={name} onChange={handleChange} onBlur={handleBlur}>
        {children}
      </select>
      <label className="user-questionnaire-container__error">{touched && error}</label>
    </div>
  );
};

export default SelectWithErrors;
