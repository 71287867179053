import client from "./client";
import Response from "./envelope";
import { ChangePassword, ResetPassword } from "./models/credentials";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";

export async function changePassword(credentials: ChangePassword): Promise<Response<ChangePassword>> {
  try {
    const response = await client.post<ChangePassword>("/rest-auth/password/change/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function passwordResetRequest(credentials: { email: string }): Promise<Response<string>> {
  try {
    const response = await client.post<string>("/rest-auth/password/reset/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function resetPassword(credentials: ResetPassword): Promise<Response<ResetPassword>> {
  try {
    const response = await client.post<ResetPassword>("/rest-auth/password/reset/confirm/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function resendEmail(email: string | null): Promise<Response<{ detail: string }>> {
  try {
    const response = await client.post<{ detail: string }>("/rest-auth/resend_confirmation/", { email });
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
