import MeState from "./state";
import { SET_ME, PATCH_ME, UNSET_ME, SET_PERMISSION } from "./actions";
import { AppAction } from "redux/actions";
import removeEmptyProperties from "utils/helpers/removeEmptyProperties";

const initialState: MeState = {
  info: null,
};

export function meReducer(state = initialState, action: AppAction): MeState {
  switch (action.type) {
    case SET_ME: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case UNSET_ME: {
      return {
        ...state,
        info: null,
      };
    }
    case SET_PERMISSION: {
      const granted_permissions = action.payload;
      if (state.info) {
        return {
          ...state,
          info: {
            ...state.info,
            granted_permissions,
          },
        };
      }
      return state;
    }
    case PATCH_ME: {
      const first_name = action.payload.first_name;
      const last_name = action.payload.last_name;
      const name = removeEmptyProperties({ first_name, last_name });
      const status = action.payload.status;
      return {
        ...state,
        info: {
          ...state.info,
          ...name,
          ...status,
        },
      };
    }
    default:
      return state;
  }
}
