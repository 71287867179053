import React, { useState } from "react";
import "./Header.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";

const Header: React.FC = () => {
  const [firstName, promoCode] = useSelector((state: RootState) => [
    state.me.info?.first_name,
    state.me.info?.promo_code,
  ]);
  const [coppiedLink, setCoppiedLink] = useState("Skopiuj link");
  const [coppiedCode, setCoppiedCode] = useState("Skopiuj kod");
  const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const copyToClipboard = async (
    str,
    setter: React.Dispatch<React.SetStateAction<string>>,
    setterString: string
  ): Promise<void> => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setter("Skopiowano!");
    await sleep(2000);
    setter(setterString);
  };

  const sendMessengerDialog = (): void => {
    (window as any).FB.ui({
      method: "send",
      link: `https://detektywi.goodwrite.pl/?promo_code=${promoCode}&`,
    });
  };
  return (
    <header className="header-ctx">
      <div className="header-ctx__flex--header header-ctx__flex">
        <div className="is-hidden-mobile">
          <span className="is-size-5">
            <h1 className="has-text-white header-ctx--header">Cześć {firstName}, co słychać?</h1>
          </span>
        </div>
        {promoCode ? (
          <div className="header-ctx__code">
            <span className="mr-2">Udostępnij kod polecający na:</span>
            <div className="header-ctx__columns">
              <div
                className="ml-2 button is-facebook header-ctx__button"
                data-href={`https://detektywi.goodwrite.pl/?promo_code=${promoCode}&`}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://detektywi.goodwrite.pl/?promo_code=${promoCode}&%2F&amp;src=sdkpreparse`}
                  className="has-text-white"
                >
                  <i className="fab fa-facebook has-text-white mr-2"></i>Facebooku
                </a>
              </div>
              <button className="ml-2 button is-link header-ctx__button" onClick={(): void => sendMessengerDialog()}>
                <i className="fab fa-facebook-messenger" aria-hidden="true"></i>Messengerze
              </button>
              <button
                className="ml-2 button is-success header-ctx__button"
                onClick={(): Promise<void> =>
                  copyToClipboard(
                    `https://detektywi.goodwrite.pl/?promo_code=${promoCode}`,
                    setCoppiedLink,
                    "Skopiuj link"
                  )
                }
              >
                {coppiedLink}
              </button>
              <button
                className="ml-2 button is-success header-ctx__button"
                onClick={(): Promise<void> => copyToClipboard(promoCode, setCoppiedCode, "Skopiuj kod")}
              >
                {coppiedCode}
              </button>
            </div>
          </div>
        ) : (
          <div className="header-ctx__code">
            <span className="mr-2">Link polecający:</span>
            <div className="header-ctx__code__wrapper">
              <div className="has-text-white header-ctx__code__wrazpper--code">Gdy rozwiążesz 3 zagadki,</div>
              <div className="has-text-white header-ctx__code__wrapper--code">otrzymasz link polecający</div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
