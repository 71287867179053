import React, { useState, useEffect, Dispatch } from "react";
import "components/app/components/questionnaire/QuestionnairePage.scss";

import { history } from "utils/history";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/app/components/Modal";
import { getQuestionnaireAction, setAcceptedAction } from "redux/questionnaire/actions";
import { getQuestionnaire, sendQuestionnaire } from "connections/questionnaire";
import { RootState } from "redux/appState";
import { useFormik } from "formik";
import Questionnaire from "./components/Questionnaire";
import extractFromFormik from "utils/helpers/extractFromFormik";
import * as Yup from "yup";
import { LocalStorageItems, QuestionnaireStatus } from "utils/enums";
import TimerBar from "./components/TimerBar";
import { asyncLocalStorage } from "utils/helpers/localStorage";

enum FontSize {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big",
}

const difficultyLevelButtons = [
  { value: 1, label: "Bardzo łatwy" },
  { value: 2, label: "Łatwy" },
  { value: 3, label: "Normalny" },
  { value: 4, label: "Trudny" },
  { value: 5, label: "Bardzo trudny" },
];

const questionnaireSchema = Yup.object({
  opinion: Yup.number().min(1, "Oceń trudność kwestionariusza przed wysłaniem."),
});

type QuestionnaireActionsTypes = ReturnType<typeof getQuestionnaireAction>;

const QuestionnairePage: React.FC = () => {
  const dispatch = useDispatch<Dispatch<QuestionnaireActionsTypes>>();
  const [fontSize, setFontSize] = useState<FontSize>(FontSize.MEDIUM);
  const [showModalSend, setShowModalSend] = useState(false);
  const [showResignModal, setShowResignModal] = useState(false);
  const [showModalEndTime, setShowModalEndTime] = useState(false);
  const [fields, setFields] = useState({});
  const [initialValues, setInitialValues] = useState({ opinion: 0 });
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [timestamps, setTimestamps] = useState({});
  const [timestampStart, setTimestampStart] = useState("");
  const questionnaireStatus = useSelector((state: RootState) => state.me.info?.status);

  useEffect(() => {
    setInitialValues({ ...fields, opinion: 0 });
    const answersFromStorage = localStorage.getItem(LocalStorageItems.ANSWERS);
    const newValues = answersFromStorage ? JSON.parse(answersFromStorage) : null;
    if (newValues) {
      setInitialValues(newValues);
    }
    const timestampsFromStorage = localStorage.getItem(LocalStorageItems.TIMESTAMPS);
    const newTimestamps = timestampsFromStorage ? JSON.parse(timestampsFromStorage) : null;
    if (newTimestamps) {
      setTimestamps(newTimestamps);
      console.log(newTimestamps);
    }
  }, [fields]);

  const text = useSelector((state: RootState) => state.questionnaire.data?.text);

  const emptyTimestampsToNulls = (): void => {
    for (const timestamp in timestamps) {
      if (timestamps[timestamp]) {
        if (timestamps[timestamp].time_in === "" || timestamps[timestamp].time_out === "") {
          timestamps[timestamp] = null;
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      emptyTimestampsToNulls();
      const { opinion, ...answers } = formik.values;
      const data = { answers, timestamps, opinion };
      setIsLoadingButton(true);
      const response = await sendQuestionnaire(data);
      if (response.status === "success") {
        dispatch(setAcceptedAction(response.data));
        await asyncLocalStorage.setItem(LocalStorageItems.QUESTIONNAIRE_STATUS, QuestionnaireStatus.SEND);
        setIsLoadingButton(false);
        history.push("/strona-glowna");
      } else {
        formik.setErrors({ opinion: "Błąd serwera. Spróbuj wysłać formularz za chwilę." });
      }
    },

    validationSchema: questionnaireSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getQuestionnaire();
      if (response.status === "success") {
        dispatch(getQuestionnaireAction(response.data));
      } else {
        localStorage.setItem(LocalStorageItems.QUESTIONNAIRE_STATUS, QuestionnaireStatus.NOT_STARTED);
        history.push("/strona-glowna");
      }
    })();
  }, [dispatch, questionnaireStatus]);

  useEffect(() => {
    const fieldIds = text.filter((element) => typeof element === "number");
    const fieldNames = fieldIds.reduce((acc, curr) => {
      return {
        ...acc,
        [`${curr}`]: "",
      };
    }, {});
    const fieldTimestamps = fieldIds.reduce((acc, curr) => {
      return {
        ...acc,
        [`${curr}`]: {
          time_in: "",
          time_out: "",
        },
      };
    }, {});
    setFields(fieldNames);
    setTimestamps(fieldTimestamps);
  }, [text]);

  const getData = (): string => {
    const data = new Date();
    const day = data.getDate();
    const month = data.getMonth() + 1;
    const year = data.getFullYear();
    const h = data.getHours();
    const min = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();
    const sec = data.getSeconds() < 10 ? `0${data.getSeconds()}` : data.getSeconds();
    return `${year}-${month}-${day}T${h}:${min}:${sec}`;
  };

  const handleFontChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setFontSize(event.target.value as FontSize);
  };

  const handleBlur = (): void => {
    localStorage.setItem(LocalStorageItems.ANSWERS, JSON.stringify(formik.values));
    localStorage.setItem(LocalStorageItems.TIMESTAMPS, JSON.stringify(timestamps));
  };

  const handleFocus = (element: number): void => {
    if (timestamps[element] === null || timestamps[element].time_in === "") {
      setTimestampStart("");
      setTimestamps({ ...timestamps, [element]: { ...timestamps[element], time_in: getData() } });
    } else {
      setTimestampStart(getData());
    }
  };
  const handleKeyUp = (element: number): void => {
    if (timestampStart !== "") {
      setTimestamps({
        ...timestamps,
        [element]: { ...timestamps[element], time_out: getData(), time_in: timestampStart },
      });
      setTimestampStart("");
    } else {
      setTimestamps({ ...timestamps, [element]: { ...timestamps[element], time_out: getData() } });
    }
  };

  const handleEndTime = (): void => {
    setShowModalEndTime(true);
  };

  const acceptActionSend = (): void => {
    localStorage.removeItem(LocalStorageItems.ANSWERS);
    localStorage.removeItem(LocalStorageItems.TIMESTAMPS);
    formik.handleSubmit();
  };
  const sendEmptyQuestionnaire = (): void => {
    localStorage.removeItem(LocalStorageItems.ANSWERS);
    localStorage.removeItem(LocalStorageItems.TIMESTAMPS);
    localStorage.setItem(LocalStorageItems.RESIGN, "TRUE");
    formik.values.opinion = 1;
    formik.handleSubmit();
  };
  const showModal = (): void => {
    formik.values.opinion = 0;
    setShowModalSend((prev) => !prev);
  };
  return (
    <main>
      <TimerBar handleEndTime={handleEndTime} />
      <form onSubmit={formik.handleSubmit} className="is-primary">
        <div className="questionnaire-container">
          <p className="questionnaire-container__p questionnaire-container__p--medium">
            Uzupełnij tekst tak, by ponownie tworzył spójną logicznie całość. Puste pola uzupełnij słowami, które w
            Twojej ocenie najlepiej pasują do kontekstu. W jedną lukę powinnaś / powinieneś wpisać jedno (i tylko jedno)
            słowo. Pamiętaj, że nie musisz uzupełniać wszystkich pól – jeśli czegoś nie wiesz, wyślij zagadkę z
            niewypełnioną luką.
          </p>
          <p className="questionnaire-container__p questionnaire-container__p--medium mb-6">
            PS. Do kolejnych pól możesz przechodzić, używając przycisku „TAB” na klawiaturze.
          </p>
          <div className="questionnaire-container__select">
            <span>Wielkość czcionki:</span>
            <div className="select">
              <select
                className="container-navbar__select-font ml-2"
                onChange={handleFontChange}
                defaultValue={FontSize.MEDIUM}
              >
                <option value={FontSize.SMALL}>Mała</option>
                <option value={FontSize.MEDIUM}>Średnia</option>
                <option value={FontSize.BIG}>Duża</option>
              </select>
            </div>
          </div>
          <div className={`questionnaire-container__text questionnaire-container__text--${fontSize}`}>
            <Questionnaire
              {...extractFromFormik(formik, ["values"])}
              handleChange={formik.handleChange}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              handleKeyUp={handleKeyUp}
            />
          </div>
          <div className="questionnaire-container__wrapper--rows mb-5">
            <button
              className="questionnaire-container__button button is-success"
              form="questionnaire-form"
              onClick={showModal}
            >
              Gotowe, wysyłam!
            </button>
            <button
              className="questionnaire-container__button button is-danger"
              type="button"
              onClick={(): void => setShowResignModal((prev) => !prev)}
            >
              Poddaję się
            </button>
          </div>
        </div>
        <Modal
          isLoading={isLoadingButton}
          visibility={showModalSend}
          handleModal={(): void => setShowModalSend((prev) => !prev)}
          headerText={"Wyślij kwestionariusz"}
          footerText={"Czy chcesz wysłać kwestionariusz?"}
          acceptAction={acceptActionSend}
          type={"submit"}
        >
          <>
            <span>
              Dobra robota, detektywie! Już prawie wszystko mamy, jeszcze tylko krótkie pytanie… Jak oceniasz trudność
              tekstu, który właśnie rozszyfrowałaś(-eś)?
            </span>
            <div className="control mt-2">
              {difficultyLevelButtons.map((button) => (
                <label className="radio" key={button.value}>
                  <input
                    className="mr-1"
                    type="radio"
                    name="opinion"
                    onClick={formik.handleChange}
                    value={button.value}
                  />
                  {button.label}
                </label>
              ))}
              <label className="control__error">{formik.errors.opinion}</label>
            </div>
          </>
        </Modal>
        <Modal
          isLoading={isLoadingButton}
          visibility={showModalEndTime}
          handleModal={(): void => setShowModalEndTime((prev) => !prev)}
          headerText={"Wysyłanie kwestionariusza"}
          footerText={"Czy jesteś gotów wysłać kwestionariusz?"}
          acceptText={"Pewnie - wyślij kwestionariusz"}
          acceptAction={acceptActionSend}
          withoutCancelButton={true}
          withoutExitButton={true}
          type={"submit"}
        >
          <>
            <span>
              Niestety, twój czas na tę zagadkę się skończył agencie. Jak oceniasz trudność tekstu, który właśnie
              rozszyfrowywałaś(-eś)?
            </span>
            <div className="control mt-2">
              {difficultyLevelButtons.map((button) => (
                <label className="radio" key={button.value}>
                  <input
                    className="mr-1"
                    type="radio"
                    name="opinion"
                    onClick={formik.handleChange}
                    value={button.value}
                  />
                  {button.label}
                </label>
              ))}
              <label className="control__error">{formik.errors.opinion}</label>
            </div>
          </>
        </Modal>
        <Modal
          isLoading={isLoadingButton}
          visibility={showResignModal}
          handleModal={(): void => setShowResignModal((prev) => !prev)}
          headerText={"Rezygnacja"}
          footerText={"Czy na pewno chcesz zrezygnować?"}
          acceptAction={sendEmptyQuestionnaire}
          type={"submit"}
        >
          <>
            <span>
              Czy jesteś pewien, że chcesz zrezygnować z tej zagadki? Po rezygnacji nie zostanie ona brana pod uwagę
              przy zliczaniu punktów.
            </span>
          </>
        </Modal>
      </form>
    </main>
  );
};

export default QuestionnairePage;
