import React, { Dispatch, useState } from "react";
import "./NavBar.scss";

import { useDispatch } from "react-redux";
import { history } from "utils/history";
import { setLoggedInAction } from "redux/auth/actions";
import { logout, logoutSession } from "connections/logout";
import { cleanLocalStorage } from "utils/helpers/localStorage";
import { unsetMeAction } from "redux/me/actions";
import { Link } from "react-router-dom";

type CotrollerNavbarActionsTypes = ReturnType<typeof setLoggedInAction | typeof unsetMeAction>;

const ControllerNavBar: React.FC = () => {
  const dispatch = useDispatch<Dispatch<CotrollerNavbarActionsTypes>>();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleLogout = async (): Promise<void> => {
    const response = await logout();
    if (response.status === "success") {
      dispatch(unsetMeAction());
      dispatch(setLoggedInAction(false));
      cleanLocalStorage();
      await logoutSession();
      history.push("/");
    } else {
      const responseAuthLogout = await logoutSession();
      if (responseAuthLogout.status === "success") {
        dispatch(unsetMeAction());
        dispatch(setLoggedInAction(false));
        cleanLocalStorage();
        history.push("/");
      }
    }
  };
  return (
    <div className="container-navbar">
      <nav className="navbar is-info" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/korektor" className="navbar-item">
            Sprawdź ankietę
          </Link>
          <Link to="/ankiety-top30" className="navbar-item">
            Top30 ankiety
          </Link>
          <Link to="/szukaj-ankiet" className="navbar-item">
            Szukaj ankiet
          </Link>
          <Link to="/sprawdzone-ankiety" className="navbar-item">
            Sprawdzone ankiety
          </Link>
          <div
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={(): void => setIsExpanded((prev) => !prev)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="navbarBasicExample" className={`navbar-menu ${isExpanded && "is-active"}`}>
          <div className="navbar-end navbar-item">
            <button className="button is-danger" onClick={handleLogout}>
              Wyloguj
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ControllerNavBar;
