import { unsetMeAction } from "redux/me/actions";
import { cleanLocalStorage } from "utils/helpers/localStorage";
import { logoutSession } from "connections/logout";
import axios from "axios";
import { setLoggedInAction } from "redux/auth/actions";
import store from "redux/store";
import { LocalStorageItems } from "utils/enums";
import { history } from "utils/history";
import { toast } from "react-toastify";

const baseURL = "https://detektywi.goodwrite.pl/api/";

const client = axios.create({
  baseURL,
});

client.interceptors.request.use((config) => {
  if (localStorage.getItem(LocalStorageItems.TOKEN)) {
    config.headers.Authorization = `Token ${localStorage.getItem(LocalStorageItems.TOKEN)}`;
  } else {
    config.headers.Authorization = null;
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response === undefined) {
      history.push("/blad-serwera");
    }
    if (error.response.status === 401 || error.response.status === 403) {
      logoutSession();
      store.dispatch(setLoggedInAction(false));
      store.dispatch(unsetMeAction());
      cleanLocalStorage();
    }
    if (error.response.status === 403) {
      toast.error(error.response.data.detail);
    }
    if (error.response.status >= 500) {
      history.push(`/blad-serwera/${error.response.status}`);
    }
    return Promise.reject(error);
  }
);
export default client;
