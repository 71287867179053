import React from "react";
import { history } from "utils/history";

interface CheckedQuestionnaireProps {
  id: string;
  checkedBy: string;
  checkedAt: string;
  idText: number;
  variant: number;
  isCorrect: boolean;
}

const CheckedQuestionnaire: React.FC<CheckedQuestionnaireProps> = ({
  id,
  idText,
  variant,
  checkedAt,
  isCorrect,
  checkedBy,
}) => {
  const params = new URLSearchParams();
  const CheckButton: JSX.Element = (
    <>
      <button
        className="button is-success controller-dashboard-container__button"
        onClick={(): void => {
          params.set("id", id);
          history.push({
            pathname: "/sprawdzona-ankieta/",
            search: params.toString(),
          });
        }}
      >
        Podejrzyj ankietę
      </button>
    </>
  );
  return (
    <tr className={isCorrect ? "" : "has-background-incorrect"}>
      <td>
        {idText} | {variant}
      </td>
      <td>{id}</td>
      <td>{checkedBy}</td>
      <td>{checkedAt}</td>
      <td>{CheckButton}</td>
    </tr>
  );
};

export default CheckedQuestionnaire;
