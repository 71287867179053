import React, { useEffect } from "react";
import "./SendEmailPage.scss";
import { LocalStorageItems, UserStatus } from "utils/enums";
import { history } from "utils/history";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { resendEmail } from "connections/auth";
import { toast } from "react-toastify";

const SendEmailPage: React.FC = () => {
  const questionnaireStatus = useSelector((state: RootState) => state.me.info?.status);

  useEffect(() => {
    if (questionnaireStatus === UserStatus.HAS_ENTRY) {
      history.push("/kwestionariusz");
    }
  }, [questionnaireStatus]);

  const handleResendEmail = async (): Promise<void> => {
    const response = await resendEmail(localStorage.getItem(LocalStorageItems.EMAIL));
    if (response.status === "success") {
      toast.success(response.data.detail);
    } else {
      toast.error("Błąd przy wysyłaniu maila");
    }
  };

  return (
    <main className="send-email-container__background">
      <div className="send-email-container">
        <h1 className="send-email-container__title">Prawie jesteś jednym z naszych detektywów!</h1>
        <div>
          <p>
            Już tylko kilka kroków dzieli Cię od rozpoczęcia swoich lingwistycznych śledztw! Teraz zaloguj się na swoje
            konto e-mail, otwórz wiadomość, który do Ciebie wysłaliśmy, i potwierdź swój adres, by móc rozwiązać
            największą zagadkę językową Warmii!
          </p>
        </div>
        <div>
          <p>
            Wiadomość e-mail nie dotarła do twojej skrzynki? Kliknij tu:
            <button className="button is-link is-light has-background-white" onClick={handleResendEmail}>
              wyślij ponownie wiadomość e-mail
            </button>
          </p>
        </div>
      </div>
    </main>
  );
};

export default SendEmailPage;
