import client from "./client";
import Response from "./envelope";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";

export async function logout(): Promise<Response<string>> {
  try {
    const response = await client.post("/rest-auth/logout/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function logoutSession(): Promise<Response<string>> {
  try {
    const response = await client.get("/api-auth/logout/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
