import React, { useState, useEffect } from "react";
import "./CheckButton.scss";

interface CheckButtonProps {
  id: number;
  userAnswer: string;
  rightAnswer: string;
  accepted: boolean | null;
  changeable: boolean;
  handleClick: () => void;
}

const CheckButton: React.FC<CheckButtonProps> = ({
  id,
  userAnswer,
  rightAnswer,
  accepted,
  handleClick,
  changeable,
}) => {
  const [className, setClassName] = useState("");
  useEffect(() => {
    if (accepted === true) {
      setClassName("is-success");
    } else if (accepted === false) {
      setClassName("is-danger");
    }
  }, [accepted]);
  return (
    <button
      className={`button check-button ${className}`}
      id={id.toString()}
      onClick={
        changeable
          ? handleClick
          : (): void => {
              return;
            }
      }
    >
      <div className="check-button__user-answer">{userAnswer === "" ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" : userAnswer}</div>
      <div className="check-button__right-answer has-text-black">{`${rightAnswer}`}</div>
    </button>
  );
};

export default CheckButton;
