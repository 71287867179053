import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { meReducer } from "./me/reducers";
import { appStatusReducer } from "./appStatus/reducers";
import { questionnaireReducer } from "./questionnaire/reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  me: meReducer,
  appStatus: appStatusReducer,
  questionnaire: questionnaireReducer,
});

export default rootReducer;
