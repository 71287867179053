import React from "react";
import { history } from "utils/history";
import { patchCheckEntry } from "connections/controller";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";

interface QuestionnaireItemProps {
  id: string;
  idUser: number;
  idText: number;
  variant: number;
  completedAt: string;
  isChecking: boolean;
}

const QuestionnaireItem: React.FC<QuestionnaireItemProps> = ({ id, idText, variant, completedAt, isChecking }) => {
  const params = new URLSearchParams();
  const checkCount = useSelector((state: RootState) => state.me.info?.entries_checking as number);
  const handleClick = async (): Promise<void> => {
    if (!isChecking) {
      await patchCheckEntry(id);
    }
    params.set("id", id);
    history.push({
      pathname: "/sprawdz-ankiete/",
      search: params.toString(),
    });
  };
  const CheckButton: JSX.Element = (
    <>
      {isChecking ? (
        <button className="button is-warning controller-dashboard-container__button" onClick={handleClick}>
          Kontynuuj
        </button>
      ) : checkCount >= 5 ? (
        <button className="button controller-dashboard-container__button" disabled>
          Masz zaczęte {checkCount} ankiet
        </button>
      ) : (
        <button className="button is-success controller-dashboard-container__button" onClick={handleClick}>
          Sprawdź
        </button>
      )}
    </>
  );
  return (
    <tr>
      <td>
        {idText} | {variant}
      </td>
      <td>{completedAt}</td>
      <td>{CheckButton}</td>
    </tr>
  );
};

export default QuestionnaireItem;
