import client from "./client";
import Response from "./envelope";
import { CheckedEntry, CheckedEntryInfo, UncheckedEntry, UncheckedEntryInfo } from "./models/credentials";
import { parseToErrorResponse, parseToSuccessResponse, parseToSuccessResponseResults } from "./models/responseParsers";

export async function getUncheckedEntries(id = 0): Promise<Response<UncheckedEntryInfo[]>> {
  const endpoint = id === 0 ? "/entry/check/" : `/entry/check/?edition=${id}`;
  try {
    const response = await client.get(endpoint);
    return parseToSuccessResponseResults(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getUncheckedTop30Entries(id = 0): Promise<Response<UncheckedEntryInfo[]>> {
  try {
    const response = await client.get(`/entry/check/?edition=${id}&winners=True`);
    return parseToSuccessResponseResults(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getCheckedEntries(): Promise<Response<CheckedEntryInfo[]>> {
  try {
    const response = await client.get("/entry/show/");
    return parseToSuccessResponseResults(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getUserEntries(id): Promise<Response<UncheckedEntryInfo[]>> {
  try {
    const response = await client.get(`entry/check/?user=${id}`);
    return parseToSuccessResponseResults(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getCheckEntry(id): Promise<Response<UncheckedEntry>> {
  try {
    const response = await client.get<UncheckedEntry>(`/entry/check/${id}/complete/`);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getCheckedEntry(id): Promise<Response<CheckedEntry>> {
  try {
    const response = await client.get<CheckedEntry>(`/entry/show/${id}/`);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function patchCheckEntry(id): Promise<Response<UncheckedEntry>> {
  try {
    const response = await client.patch<UncheckedEntry>(`/entry/check/${id}/`);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function patchCompletedCheckEntry(id, credentials): Promise<Response<UncheckedEntry>> {
  try {
    const response = await client.patch<UncheckedEntry>(`/entry/check/${id}/complete/`, credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
