import React, { useState, ReactText } from "react";
import "./LoginForm.scss";

import { useDispatch } from "react-redux";
import { setLoggedInAction } from "redux/auth/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { login, loginSocial } from "connections/login";
import InputWithError from "components/app/components/forms/InputWithError";
import { history } from "utils/history";
import { Dispatch } from "redux";
import { setMeAction } from "redux/me/actions";
import { fetchMe } from "connections/me";
import { UNKNOWN_SOCIAL_LOGIN_RESPONSE } from "connections/consts/socialResponses";
import Modal from "components/app/components/Modal";
import { toast } from "react-toastify";
import { passwordResetRequest } from "connections/auth";
import { asyncLocalStorage } from "utils/helpers/localStorage";
import { LocalStorageItems } from "utils/enums";

const validationSchema = Yup.object({
  email: Yup.string().required("Adres e-mail jest wymagany").email("Adres e-mail jest niepoprawny"),
  password: Yup.string().required("Hasło jest wymagane").min(6, "Hasło musi mieć conajmniej 6 znaków"),
});
const resetPassSchema = Yup.object({
  email: Yup.string().required("Adres e-mail jest wymagany").email("Adres e-mail jest niepoprawny"),
});

type LoginActionsTypes = ReturnType<typeof setLoggedInAction> | ReturnType<typeof setMeAction>;

const LoginForm: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const successToast = (): ReactText => toast.success("Wysłano wiadomość na podany adres e-mail");
  const dispatch = useDispatch<Dispatch<LoginActionsTypes>>();
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    setErrors,
    setSubmitting, // isSubmiting is not working for Social Buttons so I change it manually with setSubmitting to disable these buttons
    errors,
    touched,
    isSubmitting,
    values,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      loginFacebook: "",
    },
    onSubmit: async (values) => {
      const response = await login(values);
      if (response.status === "success") {
        localStorage.setItem(LocalStorageItems.TOKEN, response.data.key);
        const responseFetch = await fetchMe();
        if (responseFetch.status === "success") {
          dispatch(setLoggedInAction(true));
          dispatch(setMeAction(responseFetch.data));
        } else {
          dispatch(setLoggedInAction(false));
        }
        history.push("/strona-glowna");
      } else {
        if (response.data.non_field_errors) {
          setErrors({ password: response.data.non_field_errors[0] });
        } else {
          setErrors({ password: "Błąd serwera. Proszę spróbować później." });
        }
        setSubmitting(false);
      }
    },
    validationSchema,
  });

  const resetPassFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      await asyncLocalStorage.removeItem(LocalStorageItems.TOKEN);
      const response = await passwordResetRequest(values);
      if (response.status === "success") {
        successToast();
        setShowModal(false);
        setIsLoading(false);
      } else {
        setErrors({ email: response.data });
      }
    },
    validationSchema: resetPassSchema,
  });

  const exchangeToken = async (accessToken: string, social: string): Promise<void> => {
    const response = await loginSocial(accessToken, social);
    if (response.status === "success") {
      localStorage.setItem(LocalStorageItems.TOKEN, response.data.key);
      const responseFetchMe = await fetchMe();
      if (responseFetchMe.status === "success") {
        dispatch(setLoggedInAction(true));
        dispatch(setMeAction(responseFetchMe.data));
      } else {
        dispatch(setLoggedInAction(false));
      }
      history.push("/strona-glowna");
    } else {
      if (response.data.non_field_errors) {
        response.data.non_field_errors.forEach((error) => {
          if (social === "facebook") {
            setErrors({ loginFacebook: error });
          }
        });
      }
      setSubmitting(false);
    }
  };

  const responseFacebook = async (response: { status: string; email: string; accessToken: string }): Promise<void> => {
    if (response.status !== UNKNOWN_SOCIAL_LOGIN_RESPONSE) {
      if (response.email === undefined) {
        setErrors({ loginFacebook: "Nie zalogujesz się bez udostępniania e-maila" });
      } else {
        exchangeToken(response.accessToken, "facebook");
      }
    }
    setSubmitting(false);
  };

  return (
    <form className="form has-text-centered" onSubmit={handleSubmit}>
      <InputWithError
        name="email"
        value={values.email}
        placeholder="adres e-mail"
        type="text"
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.email}
        error={errors.email}
        className={"input is-primary"}
      />
      <InputWithError
        name="password"
        value={values.password}
        placeholder="hasło"
        type="password"
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.password}
        error={errors.password}
        className={"input is-primary"}
      />
      <div className="mt-1 has-text-left is-link" onClick={(): void => setShowModal(true)}>
        Zapomniałeś hasła?
      </div>
      <button
        className={`button is-success form__button has-text-white
        ${isSubmitting ? "is-loading" : ""}`}
        disabled={isSubmitting}
        type="submit"
      >
        Zaloguj się
      </button>
      <FacebookLogin
        appId="406229887444389"
        autoLoad={false}
        fields="name,email"
        callback={responseFacebook}
        textButton="Zaloguj się przez Facebook"
        icon="fa-facebook"
        cssClass={`button is-info form__button
          ${isSubmitting ? "is-loading" : ""}`}
        render={(renderProps): JSX.Element => (
          <button
            onClick={(...args): void => {
              renderProps.onClick(...args);
              setSubmitting(true);
            }}
            className={`button is-info form__button
              ${isSubmitting ? "is-loading" : ""}`}
            disabled={isSubmitting}
            type="button"
          >
            <i className="fab fa-facebook has-text-white" aria-hidden="true"></i>Zaloguj się przez Facebook
          </button>
        )}
      />
      <label className="error">{errors.loginFacebook}</label>
      <Modal
        visibility={showModal}
        handleModal={(): void => setShowModal((prev) => !prev)}
        headerText={"Zresetuj hasło"}
        footerText={"Czy chcesz zresetować swoje hasło?"}
        acceptAction={(): void => resetPassFormik.handleSubmit()}
        isLoading={isLoading}
        acceptText={"Zresetuj hasło"}
        cancelText={"Anuluj"}
        type={"submit"}
      >
        <h5>Wpisz adres e-mail swojego konta</h5>
        <InputWithError
          name="email"
          value={resetPassFormik.values.email}
          placeholder="adres e-mail"
          type="email"
          handleChange={resetPassFormik.handleChange}
          handleBlur={resetPassFormik.handleBlur}
          touched={resetPassFormik.touched.email}
          error={resetPassFormik.errors.email}
          className={"input is-primary"}
        />
      </Modal>
    </form>
  );
};

export default LoginForm;
