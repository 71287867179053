import React, { Dispatch } from "react";
import "./RegistrationPage.scss";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { registration } from "connections/registration";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import InputWithError from "components/app/components/forms/InputWithError";
import CheckboxWithError from "components/app/components/forms/CheckboxWithError";
import logo from "../../img/LogoPoziom.png";
import { UNKNOWN_SOCIAL_LOGIN_RESPONSE } from "connections/consts/socialResponses";
import { loginSocial } from "connections/login";
import { LocalStorageItems } from "utils/enums";
import { fetchMe } from "connections/me";
import { setLoggedInAction } from "redux/auth/actions";
import { setMeAction } from "redux/me/actions";
import { useDispatch } from "react-redux";
import { asyncLocalStorage } from "utils/helpers/localStorage";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Imię jest wymagane").max(30, "Imię jest za długie"),
  lastName: Yup.string().required("Nazwisko jest wymagane").max(150, "Nazwisko jest za długie"),
  email: Yup.string().required("Adres e-mail jest wymagany").email("Adres e-mail jest niepoprawny"),
  password: Yup.string().required("Hasło jest wymagane").min(6, "Hasło musi mieć conajmniej 6 znaków"),
  confirmPassword: Yup.string()
    .required("Powtórz hasło")
    .oneOf([Yup.ref("password")], "Hasła do siebie nie pasują"),
  acceptRegulations: Yup.bool().required("Akceptacja jest wymagana").oneOf([true], "Akceptacja jest wymagana"),
  acceptNewsletter: Yup.bool(),
  acceptAll: Yup.bool(),
});

type RegistrationActionsTypes = ReturnType<typeof setLoggedInAction> | ReturnType<typeof setMeAction>;

const RegistrationPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<RegistrationActionsTypes>>();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setErrors,
    setValues,
    errors,
    touched,
    isSubmitting,
    values,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      loginFacebook: "",
      acceptRegulations: false,
      acceptNewsletter: false,
      acceptAll: false,
    },
    onSubmit: async ({ firstName, lastName, email, password, confirmPassword, acceptNewsletter }) => {
      const response = await registration({
        first_name: firstName,
        last_name: lastName,
        email,
        password1: password,
        password2: confirmPassword,
        accepted_newsletter: acceptNewsletter,
      });
      if (response.status === "success") {
        await asyncLocalStorage.setItem(LocalStorageItems.EMAIL, email);
        history.push("/wyslano-email");
      } else if (response.status === "failure") {
        if (response.data.email) {
          setErrors({ email: response.data.email[0] });
        }
      }
    },
    validationSchema,
  });

  const exchangeToken = async (accessToken: string, social: string): Promise<void> => {
    const response = await loginSocial(accessToken, social);
    if (response.status === "success") {
      localStorage.setItem(LocalStorageItems.TOKEN, response.data.key);
      const responseFetchMe = await fetchMe();
      if (responseFetchMe.status === "success") {
        dispatch(setLoggedInAction(true));
        dispatch(setMeAction(responseFetchMe.data));
      } else {
        dispatch(setLoggedInAction(false));
      }
      history.push("/strona-glowna");
    } else {
      if (response.data.non_field_errors) {
        response.data.non_field_errors.forEach((error) => {
          if (social === "facebook") {
            setErrors({ loginFacebook: error });
          }
        });
      }
    }
  };

  const responseFacebook = async (response: { status: string; email: string; accessToken: string }): Promise<void> => {
    if (response.status !== UNKNOWN_SOCIAL_LOGIN_RESPONSE) {
      if (response.email === undefined) {
        setErrors({ loginFacebook: "Nie zalogujesz się bez udostępniania e-maila" });
      } else {
        exchangeToken(response.accessToken, "facebook");
      }
    }
  };

  const acceptAll = (): void => {
    if (!values.acceptAll) {
      setValues({ ...values, acceptNewsletter: true, acceptRegulations: true, acceptAll: true });
    } else {
      setValues({ ...values, acceptAll: false });
    }
  };

  return (
    <div className="registration-container registration-container__background">
      <main className="registration-container">
        <form className="registration-form has-text-centered" onSubmit={handleSubmit}>
          <img src={logo} alt="logo" className="registration-form__img" />
          <div className="registration-form__wrapper">
            <div className="registration-form__wrapper--column">
              <InputWithError
                value={values.firstName}
                type="text"
                placeholder="imię"
                name="firstName"
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched.firstName}
                error={errors.firstName}
                className={"input is-primary"}
              />

              <InputWithError
                type="text"
                placeholder="nazwisko"
                value={values.lastName}
                name="lastName"
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched.lastName}
                error={errors.lastName}
                className={"input is-primary"}
              />
            </div>
            <div className="registration-form__wrapper--row">
              <InputWithError
                type="email"
                placeholder="e-mail"
                value={values.email}
                name="email"
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched.email}
                error={errors.email}
                className={"input is-primary"}
              />
            </div>
            <div className="registration-form__wrapper--row">
              <InputWithError
                type="password"
                placeholder="hasło"
                value={values.password}
                name="password"
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched.password}
                error={errors.password}
                className={"input is-primary"}
              />
            </div>
            <div className="registration-form__wrapper--row">
              <InputWithError
                type="password"
                placeholder="powtórz hasło"
                value={values.confirmPassword}
                name="confirmPassword"
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched.confirmPassword}
                error={errors.confirmPassword}
                className={"input is-primary"}
              />
            </div>
          </div>
          <div className="registration-form__wrapper registration-form__wrapper--checkbox has-text-left">
            <div className="registration-form__wrapper--row">
              <CheckboxWithError
                value={values.acceptAll}
                handleChange={acceptAll}
                handleBlur={handleBlur}
                name="acceptAll"
              >
                <span>Akceptuję poniższe zgody</span>
              </CheckboxWithError>
            </div>
            <div className="registration-form__wrapper--row">
              <CheckboxWithError
                value={values.acceptRegulations}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched.acceptRegulations}
                error={errors.acceptRegulations}
                name="acceptRegulations"
              >
                <span>
                  Przeczytałam/-em i akceptuję{" "}
                  <a rel="noopener noreferrer" target="_blank" href="regulamin">
                    regulamin
                  </a>{" "}
                  konkursu „Językowi detektywi” i{" "}
                  <a rel="noopener noreferrer" target="_blank" href="polityka-prywatnosci">
                    politykę prywatności
                  </a>{" "}
                  platformy.
                  <span className="has-text-danger ml-0">*</span>
                </span>
              </CheckboxWithError>
            </div>
            <div className="registration-form__wrapper--row">
              <CheckboxWithError
                handleChange={handleChange}
                handleBlur={handleBlur}
                name="acceptNewsletter"
                value={values.acceptNewsletter}
              >
                <span>
                  Chcę otrzymywać newsletter dotyczący wyników konkursu, zmian w regulaminie, a także wyników{" "}
                  <a rel="noopener noreferrer" target="_blank" href="o-projekcie">
                    prac badawczo-rozwojowych
                  </a>{" "}
                  realizowanych przez Organizatora.
                </span>
              </CheckboxWithError>
              <div>
                <span className="has-text-danger ml-0">*Pole obowiązkowe</span>
              </div>
            </div>
          </div>
          <button
            className={`button is-success form__button 
          ${isSubmitting ? "is-loading" : ""}`}
            disabled={isSubmitting}
            type="submit"
          >
            Zarejestruj się
          </button>
          Lub
          <FacebookLogin
            appId="406229887444389"
            autoLoad={false}
            fields="name,email"
            isMobile={true}
            callback={responseFacebook}
            textButton="Zaloguj się przez Facebook"
            icon="fa-facebook"
            cssClass={`button is-info form__button 
          ${isSubmitting ? "is-loading" : ""}`}
            render={(renderProps): JSX.Element => (
              <button
                onClick={(...args): void => {
                  renderProps.onClick(...args);
                }}
                className={`button is-info form__button mt-0 
              ${isSubmitting ? "is-loading" : ""}`}
                disabled={isSubmitting}
                type="button"
              >
                <i className="fab fa-facebook has-text-white" aria-hidden="true"></i>Zarejestruj się przez Facebook
              </button>
            )}
          />
        </form>
      </main>
    </div>
  );
};

export default RegistrationPage;
