import React, { useEffect, useState } from "react";
import "./SearchTop30Page.scss";

import QuestionnaireItem from "./components/QuestionnaireItem";
import { UncheckedEntryInfo } from "connections/models/credentials";
import { getUncheckedTop30Entries } from "connections/controller";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { getEditionsInfo } from "connections/edition";

const SearchTop30Page: React.FC = () => {
  const [uncheckedEntries, setUncheckedEntries] = useState<UncheckedEntryInfo[]>([]);
  const email = useSelector((state: RootState) => state.me.info?.email);

  useEffect(() => {
    (async (): Promise<void> => {
      const responseEditions = await getEditionsInfo();
      if (responseEditions.status === "success") {
        const response = await getUncheckedTop30Entries(responseEditions.data[0].id);
        if (response.status === "success") {
          setUncheckedEntries(response.data);
        }
      }
    })();
  }, []);

  return (
    <>
      <header className="header-ctx">
        <div className="header-ctx__flex--header header-ctx__flex">Zalogowany jako: {email}</div>
      </header>
      <main className="controller-dashboard-container">
        <section className="controller-dashboard-container__section">
          <header className="controller-dashboard-container__header">Ankiety pierwszych 30 osób w rankingu:</header>
          <div className="content">
            <table className="table controller-dashboard-container__table">
              <thead>
                <tr>
                  <th>ID tekstu | Wariant</th>
                  <th>Data wypełnienia</th>
                  <th>Sprawdź ankietę</th>
                </tr>
              </thead>
              <tbody>
                {uncheckedEntries.map((questionnaire) => (
                  <QuestionnaireItem
                    key={questionnaire.id}
                    id={questionnaire.id}
                    variant={questionnaire.variant}
                    idUser={questionnaire.user}
                    idText={questionnaire.text_id}
                    completedAt={questionnaire.completed}
                    isChecking={questionnaire.is_checking}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {uncheckedEntries.length === 0 && <div>Nie ma ankiet czekających na sprawdzenie</div>}
        </section>
      </main>
    </>
  );
};

export default SearchTop30Page;
