import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NavBar from "components/app/NavBar";
import UserQuestionnaire from "components/user-questionnaire-page/UserQuestionnairePage";
import QuestionnairePage from "components/questionnaire-page/QuestionnairePage";
import DashboardPage from "components/dashboard-page/DashboardPage";
import DetectivePage from "components/info-pages/DetectivePage";
import ProjectPage from "components/info-pages/ProjectPage";
import Footer from "components/app/Footer";
import ErrorPage from "components/error-page/ErrorPage";
import RulesPage from "components/rules-page/RulesPage";
import RankingPage from "components/ranking-page/RankingPage";
import PrivacyPolicy from "components/rules-page/PrivacyPolicy";
import ArchivalRankingPage from "components/archival-ranking-page/ArchivalRankingPage";

function UserRouter(): JSX.Element {
  return (
    <>
      <NavBar />
      <div className="footer-setter">
        <Switch>
          <Route exact path="/kwestionariusz-osobowy" component={UserQuestionnaire} />
          <Route exact path="/kwestionariusz" component={QuestionnairePage} />
          <Route exact path="/o-detektywach" component={DetectivePage} />
          <Route exact path="/o-projekcie" component={ProjectPage} />
          <Route exact path="/regulamin" component={RulesPage} />
          <Route exact path="/polityka-prywatnosci" component={PrivacyPolicy} />
          <Route exact path="/ranking" component={RankingPage} />
          <Route exact path="/archiwalny-ranking" component={ArchivalRankingPage} />
          <Route exact path={["/blad-serwera", "/blad-serwera/:errorNumber"]} component={ErrorPage} />
          <Route exact path="/strona-glowna" component={DashboardPage} />
          <Redirect to="/strona-glowna" />
        </Switch>
        <Footer className="footer-container--background" />
      </div>
    </>
  );
}

export default UserRouter;
