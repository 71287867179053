import React from "react";
import ReactDOM from "react-dom";

interface PortalDialog {
  isOpen: boolean;
}

const PortalDialog: React.FC<PortalDialog> = ({ isOpen, children }) => {
  const domNode = document.querySelector("#modal-root");
  return isOpen && domNode ? ReactDOM.createPortal(children, domNode) : null;
};

export default PortalDialog;
