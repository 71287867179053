import { parseToErrorResponse, parseToSuccessResponse } from "./models/responseParsers";
import client from "./client";
import Response from "./envelope";

export async function getTimeUTC(): Promise<Response<{ current_time: string }>> {
  try {
    const response = await client.get("time");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
