import React, { useState, useEffect, ReactText } from "react";

import ReactHtmlParser from "react-html-parser";
import CheckButton from "components/app/components/questionnaire/CheckButton";
import Modal from "components/app/components/Modal";
import { history } from "utils/history";
import { getCheckEntry, patchCompletedCheckEntry } from "connections/controller";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppStatus, LocalStorageItems } from "utils/enums";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { UncheckedEntry } from "connections/models/credentials";

const CheckQuestionnaire: React.FC = () => {
  const serverToast = (): ReactText => toast.error("Błąd serwera");
  const [checkedButtons, setCheckedButtons] = useState({});
  const [credentials, setCredentials] = useState({});
  const [text, setText] = useState([""]);
  const [userAnswers, setUserAnswers] = useState({});
  const [rightAnswers, setRightAnswers] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [checkedEntry, setCheckedEntry] = useState<UncheckedEntry>();

  const location = useLocation();
  const query = queryString.parse(location.search) as { id: string };

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getCheckEntry(query.id);
      if (response.status === "success") {
        const { initial_values, text, given_answers, correct_answers } = response.data.details;
        setCheckedButtons(initial_values);
        setCredentials(initial_values);
        setUserAnswers(given_answers);
        setRightAnswers(correct_answers);
        setText(text);
        setCheckedEntry(response.data);
      }
    })();
  }, [query.id]);
  const formatText = (text: (string | number)[]): (string | number)[] => {
    text.map((el) => {
      if (typeof el !== "number") {
        return el.toString().replace(/(?:\r\n|\r|\n)/g, "<br/>");
      }
      return el;
    });
    return text;
  };
  const ifAllChecked = (checkedButtons): boolean => {
    for (const key in checkedButtons) {
      if (checkedButtons[key] === null) {
        return false;
      }
    }
    return true;
  };

  const handleClick = (id: number): void => {
    const newCheckedButtons = { ...checkedButtons, [id]: true };
    setIsAllChecked(ifAllChecked(newCheckedButtons));
    setCheckedButtons(newCheckedButtons);
    switch (credentials[id]) {
      case true:
        setCredentials({ ...credentials, [id]: false });
        break;
      default:
        setCredentials({ ...credentials, [id]: true });
        break;
    }
  };

  const content = formatText(text).map((element) => {
    if (typeof element === "number") {
      return (
        <CheckButton
          key={element}
          id={element}
          userAnswer={userAnswers[element] === undefined ? "" : userAnswers[element]}
          rightAnswer={rightAnswers[element]}
          handleClick={(): void => handleClick(element)}
          accepted={credentials[element] === undefined ? false : credentials[element]}
          changeable={credentials[element] !== undefined}
        />
      );
    }
    return ReactHtmlParser(element);
  });
  const sumbitAnswers = async (): Promise<void> => {
    setIsLoading(true);
    const data = {
      checked_answers: credentials,
      is_correct: !isBanned,
    };
    const response = await patchCompletedCheckEntry(query.id, data);
    if (response.status === "success") {
      setIsLoading(false);
      localStorage.setItem(LocalStorageItems.APP_STATUS, AppStatus.LOGGED_IN);
      history.goBack();
    } else {
      serverToast();
      setIsLoading(false);
    }
  };
  return (
    <>
      <p className="mb-4">
        Zaznacz szare odpowiedzi na zielono, jeśli wpasowują się w kontekst zdania albo na czerwono jeśli nie pasują. Po
        prawej stronie przycisku na jasnoniebiesko jest zaznaczone słowo, które było oryginalnie w tekście.
      </p>
      <p className="mb-4">
        Text id | wariant: {checkedEntry?.text_id} | {checkedEntry?.variant}
      </p>
      <p className="mb-4">Id użytkownika wypełniającego: {checkedEntry?.user}</p>
      <p className="mb-4">Id ankiety: {checkedEntry?.id}</p>
      <div className="questionnaire-container__text check-questionnaire-container__text questionnaire-container__text--medium">
        <span className="questionnaire-container__span">{content}</span>
      </div>
      <div className="checkbox mt-4">
        <label>
          <input className="mr-1" type="checkbox" name="checkbox" onClick={(): void => setIsBanned((prev) => !prev)} />
          Ta ankieta jest do zbanowania
        </label>
      </div>
      <div className="questionnaire-container__wrapper--rows">
        <button
          className="questionnaire-container__button button is-success"
          disabled={!isAllChecked && !isBanned}
          form="questionnaire-form"
          onClick={(): void => setShowModal(true)}
        >
          Gotowe, wysyłam!
        </button>
        <button
          className="questionnaire-container__button button is-danger"
          form="questionnaire-form"
          onClick={(): void => setShowCancelModal(true)}
        >
          Wróć do poprzedniej
        </button>
      </div>
      <Modal
        visibility={showModal}
        handleModal={(): void => setShowModal((prev) => !prev)}
        headerText={isBanned ? "Banowanie ankiety" : "Wyślij sprawdzenie"}
        footerText={isBanned ? "" : "Czy chcesz wysłać swoje sprawdzenie odpowiedzi?"}
        acceptAction={sumbitAnswers}
        isLoading={isLoading}
        acceptText={isBanned ? "Zbanuj ankietę" : "Wyślij"}
        cancelText={"Wróć do sprawdzania"}
      >
        <div>
          {isBanned
            ? "Czy na pewno chcesz zbanować tę ankietę?"
            : "Jeśli jesteś pewny swoich ocen wciśnij przycisk wyślij."}
        </div>
      </Modal>
      <Modal
        visibility={showCancelModal}
        handleModal={(): void => setShowCancelModal((prev) => !prev)}
        headerText={"Anuluj sprawdzenie"}
        footerText={"Czy chcesz przejść do strony głównej?"}
        acceptAction={history.goBack}
        isLoading={isLoading}
        acceptText={"Przejdź do strony głównej"}
        cancelText={"Wróć do sprawdzania"}
      >
        <div>Jeśli chcesz przejść do listy z ankietami wciśnij przejdź do strony głównej.</div>
      </Modal>
    </>
  );
};

export default CheckQuestionnaire;
