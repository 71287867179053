import React, { useEffect, useState } from "react";
import "components/failed-confirm-page/FailedEmailPage.scss";
import { UserStatus } from "utils/enums";
import { history } from "utils/history";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { resendEmail } from "connections/auth";
import { toast } from "react-toastify";

const FailedEmailPage: React.FC = () => {
  const questionnaireStatus = useSelector((state: RootState) => state.me.info?.status);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (questionnaireStatus === UserStatus.HAS_ENTRY) {
      history.push("/kwestionariusz");
    }
  }, [questionnaireStatus]);

  const handleResendEmail = async (e): Promise<void> => {
    e.preventDefault();
    const response = await resendEmail(email);
    if (response.status === "success") {
      toast.success(response.data.detail);
    } else {
      toast.error("Błąd przy wysyłaniu maila");
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <main className="send-email-container__background">
      <div className="send-email-container">
        <h1 className="send-email-container__title">Wystąpił błąd</h1>
        <div>
          <p>
            Niestety nie udało nam się potwierdzić twojego adresu e-mail z przyczyn technicznych. Wpisz swój adres
            e-mail poniżej, wciśnij wyślij, aby ponowić próbę utworzenia konta na naszej stronie. Przepraszamy za
            problemy!
          </p>
        </div>
        <form onSubmit={handleResendEmail}>
          <input type="email" className="input" placeholder="Adres e-mail..." onChange={handleChange} />
          <button type="submit" className="button is-link">
            Wyślij
          </button>
        </form>
      </div>
    </main>
  );
};

export default FailedEmailPage;
