import React from "react";
import "./QuestionnaireInput.scss";
import { FormikHandlers } from "formik";

interface QuestInput {
  id: number;
  name: string;
  value: string;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  handleFocus: (element: number) => void;
  handleKeyUp: (element: number) => void;
}

const QuestionnaireInput: React.FC<QuestInput> = ({
  id,
  name,
  value = "",
  handleChange,
  handleFocus,
  handleKeyUp,
  handleBlur,
}) => {
  return (
    <input
      name={name}
      id={id.toString()}
      value={value}
      onChange={handleChange}
      className="input questionnaire-input"
      placeholder="dopasuj słowo"
      onBlur={handleBlur}
      onKeyUp={(): void => handleKeyUp(id)}
      onFocus={(): void => handleFocus(id)}
    />
  );
};

export default QuestionnaireInput;
