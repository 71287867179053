const removeEmptyProperties = (object) => {
  for (const [key, value] of Object.entries(object)) {
    if (!value) {
      delete object[key];
    }
  }
  return object;
};

export default removeEmptyProperties;
