import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ControllerNavBar from "components/app/ControllerNavBar";
import ControllerDashboardPage from "components/controller-dashboard-page/ControllerDashboardPage";
import CheckQuestionnairePage from "components/check-questionnaire-page/CheckQuestionnairePage";
import CheckedQuestionnairePage from "components/checked-questionnaire-page/CheckedQuestionnairePage";
import CheckedQuestionnairesPage from "components/checked-questionnaries-page/CheckedQuestionnairesPage";
import ErrorPage from "components/error-page/ErrorPage";
import SearchQuestionnairePage from "components/search-questionnaires-page/SearchQuestionnairePage";
import SearchTop30Page from "components/search-top30-page/SearchTop30Page";

function ControllerRouter(): JSX.Element {
  return (
    <>
      <ControllerNavBar />
      <Switch>
        <Route exact path="/sprawdzone-ankiety" component={CheckedQuestionnairesPage} />
        <Route exact path="/blad-serwera/:errorNumber" component={ErrorPage} />
        <Route exact path="/szukaj-ankiet" component={SearchQuestionnairePage} />
        <Route exact path="/ankiety-top30" component={SearchTop30Page} />
        <Route path="/blad-serwera" component={ErrorPage} />
        <Route path="/sprawdz-ankiete/" component={CheckQuestionnairePage} />
        <Route path="/sprawdzona-ankieta/" component={CheckedQuestionnairePage} />
        <Route exact path="/korektor" component={ControllerDashboardPage} />
        <Redirect to="/korektor" />
      </Switch>
    </>
  );
}

export default ControllerRouter;
