import React, { useState, Dispatch, useEffect } from "react";
import "./DashboardPage.scss";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import bravo1 from "../../img/bravo1.gif";
import bravo2 from "../../img/bravo2.gif";
import bravo3 from "../../img/bravo3.gif";
import bravo4 from "../../img/bravo4.gif";
import bravo5 from "../../img/bravo5.gif";

import resign1 from "../../img/resign1.gif";

import detectiv from "../../img/detectiv.png";
import Header from "components/app/components/Header";
import Modal from "../app/components/Modal";
import { history } from "utils/history";
import { createQuestionnaire } from "connections/questionnaire";
import { getQuestionnaireAction } from "redux/questionnaire/actions";
import { AppStatus, LocalStorageItems, QuestionnaireStatus, UserStatus } from "utils/enums";
import { setAppStatusAction } from "redux/appStatus/actions";
import { RootState } from "redux/appState";
import { setLoggedInAction } from "redux/auth/actions";
import { fetchStats, fetchMe, setPermissions, deleteUser } from "connections/me";
import ScoreSections from "./components/ScoreSections";
import { EditionResult } from "redux/me/state";
import { EditionInfo } from "connections/models/credentials";
import { getEditionInfo } from "connections/edition";
import { setMeAction, setPermissionAction, unsetMeAction } from "redux/me/actions";
import { asyncLocalStorage, cleanLocalStorage } from "utils/helpers/localStorage";
import CheckboxWithError from "components/app/components/forms/CheckboxWithError";
import { toast } from "react-toastify";
import { logout, logoutSession } from "connections/logout";

const MAXIMUM_TRIES_NUMBER = 20;

type DashboardActionsTypes = ReturnType<
  typeof setAppStatusAction | typeof setPermissionAction | typeof setMeAction | typeof unsetMeAction
>;

const initialEditions: EditionResult[] = [];
const initialEditionInfo: EditionInfo = {
  current_edition: undefined,
  incoming_edition: undefined,
};

const validationSchema = Yup.object({
  acceptRegulations: Yup.bool().required("Akceptacja jest wymagana").oneOf([true], "Akceptacja jest wymagana"),
  acceptNewsletter: Yup.bool(),
  acceptAll: Yup.bool(),
});

const DashboardPage: React.FC = () => {
  const dispatch = useDispatch<Dispatch<DashboardActionsTypes>>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showSummaryResignModal, setShowSummaryResignModal] = useState(false);
  const [showUserQuestionnaireModal, setShowUserQuestionnaireModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [editions, setEditions] = useState(initialEditions);
  const [editionInfo, setEditionInfo] = useState(initialEditionInfo);
  const [bravoGif, setBravoGif] = useState(bravo1);
  const [resignGif] = useState(resign1);

  const [
    questionnaireStatus,
    acceptedTriedCount,
    questionnaireFilled,
    grantedPermission,
  ] = useSelector((state: RootState) => [
    state.me.info?.status,
    state.me.info?.current_tries_with_prerequisites_met,
    state.me.info?.questionnaire_filled,
    state.me.info?.granted_permissions,
  ]);

  const { handleSubmit, handleChange, handleBlur, setValues, errors, touched, values } = useFormik({
    initialValues: {
      acceptRegulations: false,
      acceptNewsletter: false,
      acceptAll: false,
    },
    onSubmit: async ({ acceptNewsletter }) => {
      const response = await setPermissions(acceptNewsletter);
      if (response.status === "success") {
        dispatch(setPermissionAction(true));
        setShowPermissionModal(false);
      }
    },
    validationSchema,
  });

  useEffect(() => {
    (async (): Promise<void> => {
      dispatch(setAppStatusAction(AppStatus.LOGGED_IN));
      if ((await asyncLocalStorage.getItem(LocalStorageItems.QUESTIONNAIRE_STATUS)) === QuestionnaireStatus.SEND) {
        await asyncLocalStorage.setItem(LocalStorageItems.QUESTIONNAIRE_STATUS, QuestionnaireStatus.NOT_STARTED);
        if ((await asyncLocalStorage.getItem(LocalStorageItems.RESIGN)) === "TRUE") {
          setShowSummaryResignModal(true);
        } else {
          setShowSummaryModal(true);
        }
        await asyncLocalStorage.removeItem(LocalStorageItems.RESIGN);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async (): Promise<void> => {
      const responseMe = await fetchMe();
      if (responseMe.status === "success") {
        dispatch(setLoggedInAction(true));
        dispatch(setMeAction(responseMe.data));
      } else {
        dispatch(setLoggedInAction(false));
      }
      const responseEditionInfo = await getEditionInfo();
      if (responseEditionInfo.status === "success") {
        setEditionInfo(responseEditionInfo.data);
      } else {
        dispatch(setLoggedInAction(false));
      }
      const responseEditions = await fetchStats();
      if (responseEditions.status === "success") {
        setEditions(responseEditions.data.results);
      } else {
        dispatch(setLoggedInAction(false));
      }
    })();
  }, [questionnaireStatus, dispatch]);

  useEffect(() => {
    if (grantedPermission === false) {
      setShowPermissionModal(true);
    }
  }, [grantedPermission]);

  useEffect(() => {
    const nr = Math.floor(Math.random() * 5) + 1;
    switch (nr) {
      case 1:
        setBravoGif(bravo1);
        break;
      case 2:
        setBravoGif(bravo2);
        break;
      case 3:
        setBravoGif(bravo3);
        break;
      case 4:
        setBravoGif(bravo4);
        break;
      case 5:
        setBravoGif(bravo5);
        break;
    }
  }, []);

  const showModal = (): void => {
    if (questionnaireFilled) {
      setShowQuestionnaireModal((previousValue) => !previousValue);
    } else {
      setShowUserQuestionnaireModal((previousValue) => !previousValue);
    }
  };

  const acceptAction = async (): Promise<void> => {
    if (questionnaireFilled) {
      setIsLoading(true);
      const response = await createQuestionnaire();
      if (response.status === "success") {
        dispatch(getQuestionnaireAction(response.data));
        setIsLoading(false);
        history.push("/kwestionariusz");
      } else {
        setIsLoading(false);
        setErrorResponse(response.data.detail);
      }
    } else {
      setIsLoading(false);
      history.push("/kwestionariusz-osobowy");
    }
  };

  const handleDeleteUser = async (): Promise<void> => {
    const response = await deleteUser();
    if (response.status === "success") {
      toast.success("Usunięto konto");
      const responseLogout = await logout();
      if (responseLogout.status === "success") {
        await logoutSession();
        dispatch(unsetMeAction());
        dispatch(setLoggedInAction(false));
        cleanLocalStorage();
        history.push("/");
      } else {
        const responseAuthLogout = await logoutSession();
        if (responseAuthLogout.status === "success") {
          dispatch(unsetMeAction());
          dispatch(setLoggedInAction(false));
          cleanLocalStorage();
          history.push("/");
        }
      }
    }
  };

  const acceptAll = (): void => {
    if (!values.acceptAll) {
      setValues({ ...values, acceptNewsletter: true, acceptRegulations: true, acceptAll: true });
    } else {
      setValues({ ...values, acceptAll: false });
    }
  };

  const StartQuestionnaireBar = (): JSX.Element => {
    const isQuestionnaireInProgress = questionnaireStatus === UserStatus.HAS_ENTRY;
    return (
      <div className="hero is-info">
        <div className="hero-body">
          <span>Rozwiąż zagadkę, aby zdobyć więcej punktów</span>
          <div className="dashboard-container__flex dashboard-container__flex--align-center">
            <img src={detectiv} alt="icon"></img>
            <button
              className={`button is-success  has-text-white mt-1`}
              disabled={acceptedTriedCount === MAXIMUM_TRIES_NUMBER ? true : false}
              onClick={isQuestionnaireInProgress ? (): void => history.push("/kwestionariusz") : showModal}
            >
              {acceptedTriedCount === MAXIMUM_TRIES_NUMBER
                ? "Wykorzystałeś wszystkie próby"
                : isQuestionnaireInProgress
                ? "Kontynuuj językowe śledztwo"
                : "Rozpocznij językowe śledztwo"}
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header />
      <ScoreSections editions={editions} editionInfo={editionInfo} StartQuestionnaireBar={StartQuestionnaireBar} />
      <Modal
        visibility={showQuestionnaireModal}
        handleModal={(): void => setShowQuestionnaireModal((prev) => !prev)}
        headerText={"Rozwiąż zagadkę"}
        footerText={"Czy na pewno chcesz teraz zająć się zagadką?"}
        acceptAction={acceptAction}
        isLoading={isLoading}
        acceptText={"Jasne, zaczynamy!"}
        cancelText={"Wrócę tu później"}
      >
        <div>
          Gotowa(y) na małe językowe śledztwo? Pamiętaj, że rozszyfrowanie tekstu-zagadki może zająć dłuższą chwilę.
          Punkty na Twoim koncie pojawią się od razu po zakończeniu śledztwa, ale wypełniony przez Ciebie tekst będzie
          dodatkowo sprawdzony przez naszych korektorów (chcemy sprawdzić, czy nie narusza zasad regulaminu).
        </div>
        <label className="error">{errorResponse}</label>
      </Modal>
      <Modal
        visibility={showUserQuestionnaireModal}
        handleModal={(): void => setShowUserQuestionnaireModal((prev) => !prev)}
        headerText={"Powiedz nam coś o sobie"}
        footerText={"Czy na pewno chcesz zająć się teraz kwestionariuszem?"}
        acceptAction={acceptAction}
        isLoading={isLoading}
        acceptText={"Pewnie, jak mus, to mus!"}
        cancelText={"Wrócę tu później"}
      >
        <div>
          Przed rozpoczęciem językowej przygody potrzebujemy kilku informacji o Tobie. To nie zajmie więcej niż 2
          minuty, a wszystkie uzyskane dane będą anonimowe – wykorzystamy je wyłącznie do celów naukowych!
        </div>
        <label className="error">{errorResponse}</label>
      </Modal>
      <Modal
        visibility={showSummaryModal}
        handleModal={(): void => setShowSummaryModal((prev) => !prev)}
        headerText={"Dzięki, agencie!"}
        acceptAction={acceptAction}
        isLoading={isLoading}
        acceptText={"Rozpocznij kolejne śledztwo"}
        cancelText={"Wróć do strony głównej"}
      >
        <div className="has-text-centered">
          <img src={bravoGif} alt="brawo detektywie" />
          <div>To jak? Bawimy się dalej czy kończymy na dziś?</div>
          <label className="error">{errorResponse}</label>
        </div>
      </Modal>
      <Modal
        visibility={showSummaryResignModal}
        handleModal={(): void => setShowSummaryResignModal((prev) => !prev)}
        headerText={"Nie poddawaj się"}
        acceptAction={acceptAction}
        isLoading={isLoading}
        acceptText={"Rozpocznij kolejne śledztwo"}
        cancelText={"Wróć do strony głównej"}
      >
        <div className="has-text-centered">
          <img src={resignGif} alt="nie poddawaj się" />
          <div>Nie udało ci się rozwiązać językowej zagadki? Nie poddawaj się! Trzymamy za ciebie kciuki.</div>
          <label className="error">{errorResponse}</label>
        </div>
      </Modal>
      <Modal
        visibility={showPermissionModal}
        handleModal={(): void => setShowPermissionModal((prev) => !prev)}
        headerText={"Zaakceptuj zgody"}
        isLoading={isLoading}
        acceptAction={handleSubmit}
        cancelAction={handleDeleteUser}
        acceptText={"Potwierdzam zaznaczone zgody"}
        cancelText={"Nie potwierdzam (usuń konto)"}
        withoutExitButton={true}
        type={"submit"}
      >
        <form>
          <div className="registration-form__wrapper--row">
            <CheckboxWithError
              value={values.acceptAll}
              handleChange={acceptAll}
              handleBlur={handleBlur}
              name="acceptAll"
            >
              <span>Akceptuję poniższe zgody</span>
            </CheckboxWithError>
          </div>
          <div className="registration-form__wrapper--row">
            <CheckboxWithError
              value={values.acceptRegulations}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.acceptRegulations}
              error={errors.acceptRegulations}
              name="acceptRegulations"
            >
              <span>
                Przeczytałam/-em i akceptuję{" "}
                <a rel="noopener noreferrer" target="_blank" href="regulamin">
                  regulamin
                </a>{" "}
                konkursu „Językowi detektywi” i{" "}
                <a rel="noopener noreferrer" target="_blank" href="polityka-prywatnosci">
                  politykę prywatności
                </a>{" "}
                platformy.
                <span className="has-text-danger ml-0">*</span>
              </span>
            </CheckboxWithError>
          </div>
          <div className="registration-form__wrapper--row">
            <CheckboxWithError
              handleChange={handleChange}
              handleBlur={handleBlur}
              name="acceptNewsletter"
              value={values.acceptNewsletter}
            >
              <span>
                Chcę otrzymywać newsletter dotyczący wyników konkursu, zmian w regulaminie, a także wyników{" "}
                <a rel="noopener noreferrer" target="_blank" href="o-projekcie">
                  prac badawczo-rozwojowych
                </a>{" "}
                realizowanych przez Organizatora.
              </span>
            </CheckboxWithError>
            <div>
              <span className="has-text-danger ml-0">*Pole obowiązkowe</span>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default DashboardPage;
