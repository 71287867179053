import React from "react";
import "./PageLoader.scss";

import { ClipLoader } from "react-spinners";

const PageLoader: React.FC = () => {
  return (
    <div className="spinner-container">
      <ClipLoader size={100} />
    </div>
  );
};

export default PageLoader;
