import { SET_APP_STATUS } from "./actions";
import { AppAction } from "redux/actions";
import AppStatusState from "./state";

const initialState: AppStatusState = {
  status: "",
};

export function appStatusReducer(state = initialState, action: AppAction): AppStatusState {
  switch (action.type) {
    case SET_APP_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    default:
      return state;
  }
}
