import client from "./client";
import Response from "./envelope";
import { Registration } from "./models/credentials";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";
import { asyncLocalStorage } from "utils/helpers/localStorage";
import { LocalStorageItems } from "utils/enums";

export async function registration(registrationData: Registration): Promise<Response<Registration>> {
  await asyncLocalStorage.removeItem(LocalStorageItems.TOKEN);
  try {
    const response = await client.post("/rest-auth/registration/", registrationData);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
