import React, { useEffect, useState } from "react";
import "./ControllerDashboardPage.scss";
import QuestionnaireItem from "./components/QuestionnaireItem";
import { EditionsInfo, UncheckedEntryInfo } from "connections/models/credentials";
import { getUncheckedEntries } from "connections/controller";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { getEditionsInfo } from "connections/edition";
import EditionList from "components/app/components/EditionList";

const ControllerDashboardPage: React.FC = () => {
  const [uncheckedEntries, setUncheckedEntries] = useState<UncheckedEntryInfo[]>([]);
  const [editionsInfo, setEditionsInfo] = useState<EditionsInfo[]>([]);
  const email = useSelector((state: RootState) => state.me.info?.email);

  useEffect(() => {
    (async (): Promise<void> => {
      const responseEditions = await getEditionsInfo();
      if (responseEditions.status === "success") {
        setEditionsInfo(responseEditions.data);
        const response = await getUncheckedEntries(responseEditions.data[0].id);
        if (response.status === "success") {
          setUncheckedEntries(response.data);
        }
      }
    })();
  }, []);

  const handleEditionChange = async (id: number): Promise<void> => {
    const responseRanking = await getUncheckedEntries(id);
    if (responseRanking.status === "success") {
      setUncheckedEntries(responseRanking.data);
    }
  };
  return (
    <>
      <header className="header-ctx">
        <div className="header-ctx__flex--header header-ctx__flex">Zalogowany jako: {email}</div>
      </header>
      <main className="controller-dashboard-container">
        <section className="controller-dashboard-container__section">
          <header className="controller-dashboard-container__header">Ankiety czekające na sprawdzenie:</header>
          <div className="mb-4 has-text-centered">
            <EditionList editionsInfo={editionsInfo} handleChange={handleEditionChange} />
          </div>
          <div className="content">
            <table className="table controller-dashboard-container__table">
              <thead>
                <tr>
                  <th>ID tekstu | Wariant</th>
                  <th>Data wypełnienia</th>
                  <th>Sprawdź ankietę</th>
                </tr>
              </thead>
              <tbody>
                {uncheckedEntries.map((questionnaire) => (
                  <QuestionnaireItem
                    key={questionnaire.id}
                    id={questionnaire.id}
                    variant={questionnaire.variant}
                    idUser={questionnaire.user}
                    idText={questionnaire.text_id}
                    completedAt={questionnaire.completed}
                    isChecking={questionnaire.is_checking}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {uncheckedEntries.length === 0 && <div>Nie ma ankiet czekających na sprawdzenie</div>}
        </section>
      </main>
    </>
  );
};

export default ControllerDashboardPage;
