import React, { useState, ReactText, useEffect, Dispatch } from "react";
import "./UserQuestionnairePage.scss";

import * as Yup from "yup";
import { useFormik } from "formik";

import Select from "../app/components/forms/SelectWithErrors";
import Checkbox from "./components/Checkbox";
import { postPersonalQuestionnaire, getReferrer } from "connections/me";
import { history } from "utils/history";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/appState";
import { getQuestionnaireAction } from "redux/questionnaire/actions";
import { ReferrerData } from "connections/models/credentials";
import asyncLocalStorage from "utils/helpers/asyncLocalStorage";
import { LocalStorageItems } from "utils/enums";

const computerKnowledgeBoxes = [
  { name: "dailyUserlabel", label: "korzystam z internetu na co dzień", value: 1 },
  { name: "emailUserlabel", label: "korzystam z poczty elektronicznej", value: 2 },
  { name: "socialUserlabel", label: "mam konto/konta na portalach społecznościowych", value: 3 },
  { name: "officeUserlabel", label: "korzystam z pakietu Office", value: 4 },
  { name: "websiteUserlabel", label: "mam swoją stronę www", value: 5 },
  { name: "programmerlabel", label: "znam przynajmniej 1 język programowania", value: 6 },
];

type AppActionsTypes = ReturnType<typeof getQuestionnaireAction>;
const uuidRegExp = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const validationSchema = Yup.object().shape({
  gender: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  age: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  citySize: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  education: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  profession: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  foreignLanguages: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  readingFrequency: Yup.number().required("To pole jest wymagane").moreThan(0, "Musisz wybrać wartość"),
  promoCode: Yup.string().matches(uuidRegExp, "Format kodu jest nieprawidłowy").nullable(),
  promoCheck: Yup.boolean(),
  dailyUser: Yup.boolean(),
  emailUser: Yup.boolean(),
  socialUser: Yup.boolean(),
  officeUser: Yup.boolean(),
  websiteUser: Yup.boolean(),
  programmer: Yup.boolean(),
  noAnswer: Yup.boolean(),
  oneOfPromo: Yup.bool().when(["promoCheck", "promoCode"], {
    is: (promoCheck, promoCode) => {
      return !promoCheck && !promoCode;
    },
    then: Yup.bool().required("Musisz podać informacje o poleceniu"),
    otherwise: Yup.bool(),
  }),
});

const UserQuestionnaire: React.FC = () => {
  const questionnaireFilled = useSelector((state: RootState) => state.me.info?.questionnaire_filled);
  const [isLoading, setIsLoading] = useState(false);
  const [referrer, setReferrer] = useState<ReferrerData>({
    first_name: "",
    last_name: "",
  });

  const dispatch = useDispatch<Dispatch<AppActionsTypes>>();

  const successToast = (): ReactText => toast.success("Wysłano kwestionariusz osobowy, możesz zacząć nowe śledztwo");

  useEffect(() => {
    if (questionnaireFilled === true) {
      history.push("/strona-glowna");
    }
  }, [questionnaireFilled]);

  const { handleSubmit, handleChange, handleBlur, setErrors, errors, touched, values } = useFormik({
    initialValues: {
      gender: 0,
      age: 0,
      citySize: 0,
      education: 0,
      profession: 0,
      foreignLanguages: 0,
      readingFrequency: 0,
      dailyUser: false,
      emailUser: false,
      socialUser: false,
      officeUser: false,
      websiteUser: false,
      programmer: false,
      noAnswer: false,
      promoCode: localStorage.getItem(LocalStorageItems.REFERRER_CODE) as string,
      promoCheck: false,
      serverError: "",
      computerKnowledge: [] as string[],
    },
    onSubmit: async ({
      gender,
      age,
      citySize,
      education,
      profession,
      foreignLanguages,
      readingFrequency,
      computerKnowledge,
      promoCode,
      promoCheck,
    }) => {
      if (promoCheck) {
        promoCode = "0cc0c000-0000-000c-000c-000c0000cccc";
      }
      const credentials = {
        gender,
        age,
        education,
        place_of_residence: citySize,
        professional_status: profession,
        foreign_languages: foreignLanguages,
        reading_frequency: readingFrequency,
        computer_knowledge: computerKnowledge.length !== 0 ? computerKnowledge.map((item) => +item) : [7],
        referrer_code: promoCode,
      };
      setIsLoading(true);
      const response = await postPersonalQuestionnaire(credentials);
      if (response.status === "success") {
        successToast();
        await asyncLocalStorage.removeItem(LocalStorageItems.REFERRER_CODE);
        setIsLoading(false);
        history.push("/strona-glowna");
      } else {
        setErrors({ serverError: response.data.detail });
      }
    },
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getReferrer(values.promoCode);
      if (response.status === "success") {
        setReferrer(response.data);
      } else {
        setReferrer({ first_name: "", last_name: "" });
      }
    })();
  }, [dispatch, values.promoCode]);

  const Selects = (): JSX.Element => (
    <div className="user-questionnaire-container__selects">
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Płeć</h2>
        <Select
          name="gender"
          value={values.gender}
          error={errors.gender}
          touched={touched.gender}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="2">kobieta</option>
          <option value="1">mężczyzna</option>
          <option value="3">nie chcę udzielać odpowiedzi na to pytanie</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Przedział wiekowy</h2>
        <Select
          name="age"
          value={values.age}
          error={errors.age}
          touched={touched.age}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">16-17 lat</option>
          <option value="2">18-24 lat</option>
          <option value="3">25-34 lat</option>
          <option value="4">35-44 lat</option>
          <option value="5">45-54 lat</option>
          <option value="6">55 lat i więcej</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Wielkość miejscowości</h2>
        <Select
          name="citySize"
          value={values.citySize}
          error={errors.citySize}
          touched={touched.citySize}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">wieś</option>
          <option value="2">małe miasto (do 20 tys. mieszkańców)</option>
          <option value="3">średnie miasto (od 20 tys. do 99 tys. mieszkańców)</option>
          <option value="4">duże miasto (od 100 tys. do 500 tys. mieszkańców)</option>
          <option value="5">wielkie miasto (powyżej 500 tys. mieszkańców)</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Wykształcenie</h2>
        <Select
          name="education"
          value={values.education}
          error={errors.education}
          touched={touched.education}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">podstawowe/gimnazjum</option>
          <option value="2">zasadnicze</option>
          <option value="3">średnie</option>
          <option value="4">pomaturalne/policealne</option>
          <option value="5">licencjat/inżynier</option>
          <option value="6">ukończone studia wyższe</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Status zawodowy</h2>
        <Select
          name="profession"
          value={values.profession}
          error={errors.profession}
          touched={touched.profession}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">uczeń/student/osoba ucząca się</option>
          <option value="2">osoba pracująca</option>
          <option value="3">osoba bezrobotna</option>
          <option value="4">emeryt/rencista</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Znajomość języków obcych</h2>
        <Select
          name="foreignLanguages"
          value={values.foreignLanguages}
          error={errors.foreignLanguages}
          touched={touched.foreignLanguages}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">nie znam żadnego języka obcego</option>
          <option value="2">znam 1 język obcy (lub więcej) w stopniu komunikatywnym</option>
          <option value="3">znam biegle 1 język obcy (lub więcej)</option>
        </Select>
      </div>
      <div>
        <h2 className="has-text-left user-questionnaire-container__title">Ile czasu poświęcasz na czytanie?</h2>
        <Select
          name="readingFrequency"
          value={values.readingFrequency}
          error={errors.readingFrequency}
          touched={touched.readingFrequency}
          handleBlur={handleBlur}
          handleChange={handleChange}
        >
          <option value="0">wybierz</option>
          <option value="1">nie czytam w ogóle</option>
          <option value="2">do 10 minut dziennie</option>
          <option value="3">od 10 minut do 30 minut dziennie</option>
          <option value="4">powyżej 30 minut dziennie</option>
        </Select>
      </div>
    </div>
  );
  const Checkboxes = (): JSX.Element => (
    <div className="has-text-left">
      <h2 className="has-text-left user-questionnaire-container__title">Poziom znajomości technik komputerowych</h2>
      <div>
        {computerKnowledgeBoxes.map((box) => (
          <Checkbox
            key={box.name}
            className="user-questionnaire-container__checkbox checkbox"
            name={"computerKnowledge"}
            value={box.value}
            id={box.name}
            handleBlur={handleBlur}
            handleChange={handleChange}
            checked={values.computerKnowledge.includes(box.value.toString())}
          >
            <span>{box.label}</span>
          </Checkbox>
        ))}
        <label className="user-questionnaire-container__error">{touched.dailyUser && (errors as any).oneOfBool}</label>
      </div>
    </div>
  );
  return (
    <main className="user-questionnaire-container">
      <h1 className="user-questionnaire-container__header">
        Zanim rozpoczniesz śledztwo, podaj nam, agencie, kilka informacji o sobie
      </h1>
      <h1 className="user-questionnaire-container__header--small">
        Wszystkie poniższe informacje wykorzystamy wyłącznie w celach naukowych – piszemy o tym więcej w naszej{" "}
        <a rel="noopener noreferrer" target="_blank" href="polityka-prywatnosci">
          polityce prywatności
        </a>
      </h1>
      <form onSubmit={handleSubmit} className="user-questionnaire-container__form">
        <Selects />
        <Checkboxes />
        <div className="has-text-left">
          <h2 className="has-text-left user-questionnaire-container__title">
            Wpisz kod osoby, która cię poleciła{" "}
            {referrer.first_name && `(${referrer.first_name} ${referrer.last_name})`}
          </h2>
          <input
            className="user-questionnaire-container__input input is-primary"
            placeholder="Kod polecający"
            type="text"
            name="promoCode"
            disabled={values.promoCheck}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.promoCode}
          />
          <label className="user-questionnaire-container__error">{touched.dailyUser && errors.promoCode}</label>
          <Checkbox
            className="user-questionnaire-container__checkbox checkbox"
            name="promoCheck"
            value={values.promoCheck}
            handleBlur={handleBlur}
            handleChange={handleChange}
            checked={values.promoCheck}
          >
            <span>nikt mnie nie polecił</span>
          </Checkbox>
          <label className="user-questionnaire-container__error">
            {touched.dailyUser && (errors as any).oneOfPromo}
          </label>
        </div>
        <div className="mb-4">
          <div className="user-questionnaire-container__buttons">
            <button className={`button is-success  ${isLoading ? "is-loading" : ""}`} type="submit">
              Wyślij i zacznij śledztwo
            </button>
            <button
              className={`button is-danger  ${isLoading ? "is-loading" : ""}`}
              type="button"
              onClick={(): void => history.push("/strona-glowna")}
            >
              Wróć do strony głównej
            </button>
          </div>
          <label className="user-questionnaire-container__error">{errors.serverError}</label>
        </div>
      </form>
    </main>
  );
};

export default UserQuestionnaire;
