import client from "./client";
import Response from "./envelope";
import { Login, LoginResponse } from "./models/credentials";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";
import { asyncLocalStorage } from "utils/helpers/localStorage";
import { LocalStorageItems } from "utils/enums";

export async function login(credentials: Login): Promise<Response<Login, LoginResponse>> {
  await asyncLocalStorage.removeItem(LocalStorageItems.TOKEN);
  try {
    const response = await client.post<LoginResponse>("/rest-auth/login/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function loginSocial(credentials: string, socialSite: string): Promise<Response<LoginResponse>> {
  await asyncLocalStorage.removeItem(LocalStorageItems.TOKEN);
  try {
    const response = await client.post<LoginResponse>(`/rest-auth/${socialSite}/`, { access_token: credentials });
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
