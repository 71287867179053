import React, { useEffect, useState } from "react";
import "./TimerBar.scss";

import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { getTimeUTC } from "connections/time";

interface TimerBarProps {
  handleEndTime: () => void;
}

const TimerBar: React.FC<TimerBarProps> = ({ handleEndTime }) => {
  const startTime = useSelector((state: RootState) => state.questionnaire.data.created);

  const [milisecondsLeft, setMilisecondsLeft] = useState(1800000);
  const [minutesLeft, setMinutesLeft] = useState(Math.floor(milisecondsLeft / 60000));
  const [secondsLeft, setSecondsLeft] = useState((milisecondsLeft - minutesLeft * 60000) / 1000);

  const formatTime = (time): string => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getTimeUTC();
      if (response.status === "success") {
        const msForEntry = 780000;
        const endTime = Date.parse(startTime) + msForEntry;
        const currentData = response.data.current_time;
        setMilisecondsLeft(endTime - Date.parse(currentData));
        setMinutesLeft(Math.floor(milisecondsLeft / 60000));
        setSecondsLeft((milisecondsLeft - minutesLeft * 60000) / 1000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    if (milisecondsLeft > 0 || isNaN(milisecondsLeft)) {
      setMinutesLeft(Math.floor(milisecondsLeft / 60000));
      setSecondsLeft(Math.floor((milisecondsLeft - minutesLeft * 60000) / 1000));
      const interval = setInterval(() => {
        setMilisecondsLeft((prevState) => prevState - 1000);
      }, 1000);
      return (): void => clearInterval(interval);
    } else {
      setMinutesLeft(0);
      setSecondsLeft(0);
      handleEndTime();
    }
  }, [minutesLeft, milisecondsLeft, handleEndTime]);

  return (
    <div className="container-navbar container-navbar__navbar timer">
      <nav
        className="navbar navbar-questionnarie container-navbar__content is-info"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item has-text-white">
            Do końca śledztwa pozostało:{" "}
            <span className="ml-1">
              {formatTime(minutesLeft)}:{formatTime(secondsLeft)}
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TimerBar;
