import React, { useEffect, useState } from "react";
import "components/archival-ranking-page/ArchivalRankingPage.scss";
import { EditionsInfo } from "connections/models/credentials";
import { getEditionsInfo } from "connections/edition";
import EditionList from "../app/components/EditionList";
import Ranking from "./components/Ranking";
import archivalRanking from "./archivalRanking";

const ArchivalRankingPage: React.FC = () => {
  const [editionsInfo, setEditionsInfo] = useState<EditionsInfo[]>([]);
  const [editionId, setEditionsId] = useState(7);
  useEffect(() => {
    (async (): Promise<void> => {
      const responseEditions = await getEditionsInfo();
      if (responseEditions.status === "success") {
        setEditionsInfo(responseEditions.data);
        setEditionsId(responseEditions.data[1].id);
      }
    })();
  }, []);
  const handleEditionChange = (id: number): void => {
    setEditionsId(id);
  };
  return (
    <div className="ranking-container">
      <header>
        <h1 className="mr-2">Ranking edycji:</h1>
        <EditionList
          editionsInfo={editionsInfo}
          handleChange={handleEditionChange}
          seperatorId={6}
          withoutFirst={true}
        />
      </header>

      <main>
        <Ranking rankingInfo={archivalRanking[editionId]} />
      </main>
    </div>
  );
};

export default ArchivalRankingPage;
