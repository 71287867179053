import React, { useEffect, useState } from "react";
import "./SearchQuestionnairePage.scss";
import QuestionnaireItem from "./components/QuestionnaireItem";
import { useSelector } from "react-redux";
import { RootState } from "redux/appState";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUserEntries } from "connections/controller";
import { UncheckedEntryInfo } from "connections/models/credentials";
import { asyncLocalStorage } from "utils/helpers/localStorage";
import { LocalStorageItems } from "utils/enums";

const validationSchema = Yup.object({
  id: Yup.number().typeError("ID musi być liczbą").required("ID jest wymagane"),
});

const SearchQuestionnairePage: React.FC = () => {
  const email = useSelector((state: RootState) => state.me.info?.email);
  const [uncheckedEntries, setUncheckedEntries] = useState<UncheckedEntryInfo[]>([]);
  const [firstSubmit, setFirstSubmit] = useState(false);

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      id: 0,
    },
    onSubmit: async ({ id }) => {
      setFirstSubmit(true);
      const response = await getUserEntries(id);
      if (response.status === "success") {
        setUncheckedEntries(response.data);
      }
      await asyncLocalStorage.setItem(LocalStorageItems.SEARCH_USER_ID, id.toString());
    },
    validationSchema,
  });

  useEffect(() => {
    if (localStorage.getItem(LocalStorageItems.SEARCH_USER_ID)) {
      values.id = parseInt(localStorage.getItem(LocalStorageItems.SEARCH_USER_ID) as string);
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="header-ctx">
        <div className="header-ctx__flex--header header-ctx__flex">Zalogowany jako: {email}</div>
      </header>
      <main className="controller-dashboard-container">
        <section className="controller-dashboard-container__section">
          <header className="controller-dashboard-container__header">
            <h1>Wpisz id użytkownika:</h1>
            <form className="controller-dashboard-container__form  mr-4" onSubmit={handleSubmit}>
              <div>
                <input className="input" type="text" name="id" value={values.id} onChange={handleChange} />
                <input className="input" type="submit" value="Szukaj ankiet" />
              </div>
              <label className="error">{errors.id}</label>
            </form>
          </header>

          <div className="content">
            <table className="table controller-dashboard-container__table">
              <thead>
                <tr>
                  <th>ID tekstu | Wariant</th>
                  <th>Data wypełnienia</th>
                  <th>Sprawdź ankietę</th>
                </tr>
              </thead>
              <tbody>
                {uncheckedEntries.map((questionnaire) => (
                  <QuestionnaireItem
                    key={questionnaire.id}
                    id={questionnaire.id}
                    variant={questionnaire.variant}
                    idUser={questionnaire.user}
                    idText={questionnaire.text_id}
                    completedAt={questionnaire.completed}
                    isChecking={questionnaire.is_checking}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {firstSubmit && uncheckedEntries.length === 0 && (
            <div>Ten user nie ma ankiet oczekujących na sprawdzenie</div>
          )}
        </section>
      </main>
    </>
  );
};

export default SearchQuestionnairePage;
