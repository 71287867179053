import React, { ButtonHTMLAttributes } from "react";
import "./Modal.scss";

import PortalDialog from "hoc/PortalDialog";

interface ModalProps {
  visibility: boolean;
  headerText?: string;
  footerText?: string;
  acceptText?: string;
  cancelText?: string;
  isLoading?: boolean;
  withoutCancelButton?: boolean;
  withoutExitButton?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  acceptAction?: () => void | Promise<void> | undefined;
  cancelAction?: () => void | Promise<void> | undefined;
  handleModal: () => void | undefined;
}

const Modal: React.FC<ModalProps> = ({
  visibility,
  handleModal,
  headerText,
  footerText,
  acceptText = "Tak",
  cancelText = "Nie",
  acceptAction,
  cancelAction,
  withoutCancelButton = false,
  withoutExitButton = false,
  isLoading,
  type,
  children,
}) => {
  return (
    <PortalDialog isOpen={visibility}>
      <div className={`modal ${visibility ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <h1 className="modal-card-title">{headerText}</h1>
            {!isLoading && !withoutCancelButton && !withoutExitButton ? (
              <button className="delete" aria-label="close" onClick={handleModal}></button>
            ) : null}
          </header>
          <section className="modal-card-body">
            <div className="content">{children}</div>
          </section>
          <footer className="modal-card-foot">
            <div>
              <h5>{footerText}</h5>
            </div>
            <div className="modal__buttons">
              <button
                className={`button is-success  modal__button ${isLoading ? "is-loading" : ""}`}
                onClick={acceptAction ? acceptAction : handleModal}
                type={type}
              >
                {acceptText}
              </button>
              {!withoutCancelButton && (
                <button
                  className={`button is-danger  modal__button ${isLoading ? "is-loading" : ""}`}
                  onClick={cancelAction ? cancelAction : handleModal}
                >
                  {cancelText}
                </button>
              )}
            </div>
          </footer>
        </div>
      </div>
    </PortalDialog>
  );
};

export default Modal;
