import { RankingInfo } from "connections/models/credentials";
import React from "react";

interface RankingProps {
  rankingInfo: RankingInfo | null;
}

const Ranking: React.FC<RankingProps> = ({ rankingInfo }) => {
  return (
    <div className="ranking-container__ranking">
      <div className="ranking-container__place">
        <div className="rank ranking-container__place--bold">Miejsce</div>
        <div className="email ranking-container__place--bold">Adres e-mail</div>
        <div className="points ranking-container__place--bold">Punkty</div>
      </div>
      {rankingInfo &&
        rankingInfo.results.map((user) => (
          <div
            key={user.rank}
            className={`ranking-container__place ${
              user.rank === rankingInfo.user_rank ? "ranking-container__place--self" : ""
            }`}
          >
            <div className="rank ranking-container__place--big">{user.rank}</div>
            <div className="email">{user.email}</div>
            <div className="points">{user.total_points}</div>
          </div>
        ))}
    </div>
  );
};

export default Ranking;
