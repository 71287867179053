import React, { useState, useEffect } from "react";

import ReactHtmlParser from "react-html-parser";
import CheckButton from "components/app/components/questionnaire/CheckButton";
import { getCheckedEntry } from "connections/controller";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { history } from "utils/history";
import { CheckedEntry } from "connections/models/credentials";

const CheckQuestionnaire: React.FC = () => {
  const [text, setText] = useState([""]);
  const [userAnswers, setUserAnswers] = useState({});
  const [rightAnswers, setRightAnswers] = useState({});
  const [credentials, setCredentials] = useState({});
  const [checkedEntry, setCheckedEntry] = useState<CheckedEntry>();

  const location = useLocation();
  const query = queryString.parse(location.search) as { id: string };

  useEffect(() => {
    (async (): Promise<void> => {
      const response = await getCheckedEntry(query.id);
      if (response.status === "success") {
        const { initial_values, text, given_answers, correct_answers } = response.data.details;
        setUserAnswers(given_answers);
        setRightAnswers(correct_answers);
        setCredentials(initial_values);
        setText(text);
        setCheckedEntry(response.data);
      } else {
        history.push("/sprawdzone-ankiety");
      }
    })();
  }, [query.id]);
  const formatText = (text: (string | number)[]): (string | number)[] => {
    text.map((el) => {
      if (typeof el !== "number") {
        return el.toString().replace(/(?:\r\n|\r|\n)/g, "<br/>");
      }
      return el;
    });
    return text;
  };

  const content = formatText(text).map((element) => {
    if (typeof element === "number") {
      return (
        <CheckButton
          key={element}
          id={element}
          userAnswer={userAnswers[element] === undefined ? "" : userAnswers[element]}
          rightAnswer={rightAnswers[element]}
          handleClick={(): void => {}}
          accepted={credentials[element] === undefined ? false : credentials[element]}
          changeable={credentials[element] !== undefined}
        />
      );
    }
    return ReactHtmlParser(element);
  });
  return (
    <>
      <p className="mb-4">
        Text id | wariant: {checkedEntry?.text_id} | {checkedEntry?.variant}
      </p>
      <p className="mb-4">Nazwa korektora: {checkedEntry?.checked_by}</p>
      <p className="mb-4">Id użytkownika wypełniającego: {checkedEntry?.user}</p>
      <p className="mb-4">Id ankiety: {checkedEntry?.id}</p>
      <p className="mb-4">Czy zaakceptowana? {checkedEntry?.is_correct ? "Tak" : "Nie"}</p>
      <div className="questionnaire-container__text check-questionnaire-container__text questionnaire-container__text--medium">
        <span className="questionnaire-container__span">{content}</span>
      </div>
    </>
  );
};

export default CheckQuestionnaire;
