import { AppAction } from "redux/actions";
import { Me } from "./state";
import { PatchMe } from "connections/models/credentials";

const moduleName = "me";
export const SET_ME = `${moduleName}/SET_ME`;
export const SET_PERMISSION = `${moduleName}/SET_PERMISSION`;
export const UNSET_ME = `${moduleName}/UNSET_ME`;
export const PATCH_ME = `${moduleName}/PATCH_ME`;

export const setMeAction = (payload: Me | null): AppAction => ({ type: SET_ME, payload });
export const unsetMeAction = (): AppAction => ({ type: UNSET_ME });
export const patchMeAction = (payload: PatchMe | null): AppAction => ({ type: PATCH_ME, payload });
export const setPermissionAction = (payload: boolean): AppAction => ({ type: SET_PERMISSION, payload });
