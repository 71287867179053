import { LocalStorageItems } from "../enums";

export const asyncLocalStorage = {
  setItem: async function (key: string, value: string): Promise<void> {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key: string): Promise<string | null> {
    await null;
    return localStorage.getItem(key);
  },
  removeItem: async function (key: string): Promise<void | null> {
    await null;
    return localStorage.removeItem(key);
  },
};

export const cleanLocalStorage = (): void => {
  localStorage.removeItem(LocalStorageItems.APP_STATUS);
  localStorage.removeItem(LocalStorageItems.QUESTIONNAIRE_STATUS);
  localStorage.removeItem(LocalStorageItems.TOKEN);
};
