import React from "react";
import { FormikHandlers } from "formik";

interface Checkbox {
  id?: string;
  value: any;
  name: string;
  className?: string;
  disabled?: boolean;
  handleChange: (e) => void;
  handleBlur: FormikHandlers["handleBlur"];
  checked: boolean;
}

const Checkbox: React.FC<Checkbox> = ({
  className,
  name,
  disabled,
  handleChange,
  handleBlur,
  children,
  id,
  value,
  checked,
}) => {
  return (
    <div className={`${className}`}>
      <label>
        <input
          id={id}
          type="checkbox"
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          disabled={disabled}
          checked={checked}
          value={value}
        />
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
