import client from "./client";
import Response from "./envelope";
import { Questionnaire } from "redux/questionnaire/state";
import { parseToSuccessResponse, parseToErrorResponse } from "./models/responseParsers";

export async function createQuestionnaire(): Promise<Response<Questionnaire>> {
  try {
    const response = await client.post("/quest/entry/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function getQuestionnaire(): Promise<Response<Questionnaire>> {
  try {
    const response = await client.get("/quest/entry/complete/");
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}

export async function sendQuestionnaire(credentials): Promise<Response<Questionnaire>> {
  try {
    const response = await client.patch("/quest/entry/complete/", credentials);
    return parseToSuccessResponse(response);
  } catch (error) {
    return parseToErrorResponse(error);
  }
}
